import { Document, Page, StyleSheet, Font } from "@react-pdf/renderer";
import ReceiptTitle from "./ReceiptTitle";
import ReceiptItemsTable from "./ReceiptItemsTable";
import { SummaryDto } from "../../app/models/summary/summaryDto";
import ReceiptHeader from "./ReceiptHeader";
import ReceiptFooter from "./ReceiptFooter";
import ReceiptItems from "./ReceiptItems";

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf', fontWeight: 800 }
    ]
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: 'Helvetica'
    }
});

interface Props {
    summaryData: SummaryDto[][] | null;
}

export default function PdfDocument({ summaryData }: Props) {
    return (
        <>
            <Document>
                <Page style={styles.body}>
                    <ReceiptHeader />
                    <ReceiptTitle />
                    <ReceiptItems />
                    <ReceiptItemsTable summaryData={summaryData} />
                    <ReceiptFooter />
                </Page>
            </Document >
        </>)
}
