import { Text, StyleSheet, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    titleContainer: {
        flexDirection: 'row',
        marginTop: 24,
        fontSize: 9,
        lineHeight: 1.5
    }
});

const ReceiptFooter = () => {
    return (
        <View style={styles.titleContainer}>
            <Text>
                * Partial Year Completed.
            </Text>
        </View>
    )
}

export default ReceiptFooter;