import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { SummaryDto } from '../../app/models/summary/summaryDto';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 24,
        fontSize: 9
    },
    PcsYear: {
        width: '50%',
        textAlign: 'center'
    },
    EngagedInPracticeOfMedicine: {
        width: '50%',
        textAlign: 'center'
    },
    item: {
        width: '50%',
        paddingTop: '5px',
        height: '20',
        textAlign: 'center'
    },
    OnTarget: {
        width: '30%',
        textAlign: 'center'
    },
    VerificationStatus: {
        width: '40%',
        textAlign: 'center',
        paddingLeft: '7px'
    }
});

interface Props {
    summaryData: SummaryDto[][] | null;
}

const ReceiptTableRow = ({ summaryData }: Props) => {
    return (
        <>
            {summaryData?.map((item: SummaryDto[], index) => (
                <View style={styles.row} key={index}>
                    <Text style={styles.PcsYear}>
                        {item[0].text}
                        {item[2].text.toLowerCase() === "true" ? "*" : ""}
                    </Text>

                    <Text style={styles.EngagedInPracticeOfMedicine}>
                        {item[1].text}
                    </Text>

                    <Text style={styles.item}>
                        {item[3].text}
                    </Text>

                    <Text style={styles.item}>
                        {item[4].text}
                    </Text>

                    <Text style={styles.item}>
                        {item[5].text}
                    </Text>

                    <Text style={styles.item}>
                        {item[6].text}
                    </Text>

                    <Text style={styles.OnTarget}>
                        {item[7].text}
                    </Text>

                    <Text style={styles.VerificationStatus}>
                        {item[8].text}
                    </Text>
                </View >
            ))}
        </>
    )
};

export default ReceiptTableRow;