import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getCreditSummaryAsync } from '../slices/pcsSlice';

export const useGetCreditSummary = (reload = false) => {
    const dispatch = useAppDispatch();
    const { summaryData, getCreditSummaryStatus } = useAppSelector(state => state.pcs);

    useEffect(() => {
        if ((summaryData === null || reload === true) && getCreditSummaryStatus === ApiRequestStatus.Idle) {
            dispatch(getCreditSummaryAsync());
        }
    }, [summaryData, getCreditSummaryStatus, dispatch]);

    return { getCreditSummaryStatus };
};
