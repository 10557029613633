import { Box, Button, Grid, Typography } from "@mui/material";
import AppTextareaInput from "../../../../../app/components/AppTextareaInput";
import { useForm } from "react-hook-form";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import { PdpStatusEnum } from "../../../models/pdpStatusEnum";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/store/configureStore";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { ApiRequestStatus } from "../../../../../app/enums/apiRequestStatus";
import { saveRevieweeCommentsAsync } from "../../../../../app/slices/pcsPdpSlice";
import { Link, useHistory, useParams } from "react-router-dom";
import { API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_PROCESSING_DETAILS } from "../../../../../app/utils/constant";
import { toast } from "react-toastify";
import { RevieweeCommentDto } from "../../../models/revieweeCommentDto";
import { yupResolver } from "@hookform/resolvers/yup";
import { feedbackValidationSchema } from "../../../common/pcsPlanValidationSchema";
import { getPcsEnrolmentYearsAsync } from "../../../../../app/slices/pcsEnrolmentSlice";
import { AppPathEnum } from "../../../../../app/enums/appPathEnum";
import { getReferenceDataAsync } from "../../../../../app/slices/referenceDataSlice";
import { getPcsCurrentYearAsync } from "../../../../../app/slices/pcsSlice";

export default function Feedback() {
    const { ReviewerComments, PractionerComments } = pcsPlanFormModel.formField;

    const { control, getValues, reset } = useForm({
        resolver: yupResolver(feedbackValidationSchema)
    });

    const [canAddComments, setCanAddComments] = useState<boolean>(false);
    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();
    const history = useHistory();

    const dispatch = useAppDispatch();

    const { pcsPdpStatuses, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { enrolmentYears, enrolmentYearsStatus } = useAppSelector(state => state.pcsEnrolment);
    const { currentYear, currentYearStatus } = useAppSelector(state => state.pcs);

    useEffect(() => {
        dispatch(getReferenceDataAsync());
        dispatch(getPcsEnrolmentYearsAsync());
        dispatch(getPcsCurrentYearAsync());
    }, []);

    useEffect(() => {
        if (pcsPdpStatuses && enrolmentYears) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId)
            reset({
                [ReviewerComments.name]: enrolmentYear?.pdpReviewerComments,
                [PractionerComments.name]: enrolmentYear?.pdpRevieweeComments
            });

            const pdpStatus = pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId);
            setCanAddComments(enrolmentYear?.year === currentYear && pdpStatus?.code === PdpStatusEnum.Reviewed);
        }
    }, [pcsPdpStatuses, enrolmentYears]);

    const addCommentsAsync = async () => {

        let revieweeCommentDto: RevieweeCommentDto = {
            comments: getValues([PractionerComments.name]).toString(),
            enrolmentYearId: enrolmentYearId
        };
        dispatch(saveRevieweeCommentsAsync(revieweeCommentDto)).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                dispatch(getPcsEnrolmentYearsAsync()).then((response) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        history.push(`${AppPathEnum.PCS_PLAN_DETAILS}/${enrolmentYearId}${window.location.search}`);
                        toast.success(MESSAGE_DETAILS_SAVED);
                    }
                });
            }
        });
    };

    if (staticDataStateStatus === ApiRequestStatus.Pending
        || enrolmentYearsStatus === ApiRequestStatus.Pending
        || currentYearStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant='h4'>Feedback</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput
                        control={control}
                        name={ReviewerComments.name}
                        label={ReviewerComments.label}
                        disabled
                    />
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput
                        control={control}
                        name={PractionerComments.name}
                        label={PractionerComments.label}
                        information_text_with_label={'Please note these comments are not shared with your reviewer, they are for self-reflection purposes.'}
                    />
                </Grid>

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={3} sm={2} sx={{ display: 'flex', justifyContent: 'start' }}>
                    <Button component={Link} to={`${AppPathEnum.PCS_PLAN_DETAILS}/${enrolmentYearId}`}>
                        Cancel
                    </Button>
                </Grid>

                {canAddComments === true &&
                    <Grid item xs={9} sm={3} sx={{ display: 'flex', justifyContent: 'start' }}>
                        <Button variant="contained" color="primary" onClick={() => addCommentsAsync()}>
                            Add comments
                        </Button>
                    </Grid>
                }

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}