import { Box, Grid, Button } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import AppAutocomplete from "../../../app/components/AppAutocomplete";
import AppDatePicker from "../../../app/components/AppDatePicker";
import AppTextInput from "../../../app/components/AppTextInput";
import { useGetRegistrationDetails } from "../../../app/customHooks/useGetRegistrationDetails";
import { useGetWorkpostList } from "../../../app/customHooks/useGetWorkpostList";
import { ApiRequestStatus } from "../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { IdTokenClaims } from "../../../app/models/account/idTokenClaims";
import registrationFormModel from "../../../app/models/pcsEnrolment/registrationFormModel";
import { WorkPostLevel } from "../../../app/models/staticData/workPostLevel";
import { WorkPostDto } from "../../../app/models/workpost/workPostDto";
import { useAppSelector } from "../../../app/store/configureStore";
import { MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";
import { MapCountriesToAutocompleteItems, MapHospitalsToAutocompleteItems } from "../../../app/utils/convertStaticDataToAutocompleteItems";
import { addDays } from "../../../app/utils/util";

interface Props {
  backToWorkDetailsClick?: () => void;
  handleSave?: () => void;
}

export default function AddWorkDetails({ backToWorkDetailsClick, handleSave }: Props) {
  const methods = useFormContext();
  const { control, getValues, watch, reset } = methods;

  const { countries, workPostLevels, hospitals } = useAppSelector(state => state.ReferenceData);
  const { registrationDetails } = useAppSelector(state => state.pcs);
  const { workpostList, workpostListStatus, workpostStatus, saveWorkpostStatus } = useAppSelector(state => state.workpost);

  const [showOtherNameOfEmployer, setShowOtherNameOfEmployer] = useState<boolean>(false);
  const [latestWorkpost, setLatestWorkpost] = useState<WorkPostDto>();

  const {
    workDetailsFormField: {
      StartDate,
      PostLevel,
      Country,
      NameOfClinicalSupervisor,
      NameOfEmployer,
      OtherNameOfEmployer,
      JobTitle,
      UserRoles
    }
  } = registrationFormModel;

  const { instance } = useMsal();
  const userRoles = (instance.getActiveAccount()?.idTokenClaims as unknown as IdTokenClaims).extension_portalcrm_userType.split(',');

  useGetWorkpostList();
  useGetRegistrationDetails();

  useEffect(() => {
    if (workpostList && workpostList.length > 0) {
      setLatestWorkpost(workpostList?.slice().sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())[0]);
    }
  }, [workpostList]);

  useEffect(() => {
    reset({
      [StartDate.name]: null,
      [PostLevel.name]: '',
      [Country.name]: '',
      [NameOfClinicalSupervisor.name]: '',
      [NameOfEmployer.name]: '',
      [OtherNameOfEmployer.name]: '',
      [JobTitle.name]: '',
      [UserRoles.name]: userRoles
    });
  }, [reset]);

  useEffect(() => {
    watch((_, { name, type }) => {
      if (name === NameOfEmployer.name && type === 'change') {
        if (getValues(NameOfEmployer.name) === '') {
          setShowOtherNameOfEmployer(true);
        } else {
          setShowOtherNameOfEmployer(false);
        }
      }
    });
  }, [NameOfEmployer.name, watch, getValues]);

  const populateWorkPostLevels = () => {
    const workPostLevelList = workPostLevels?.filter(x => x.isPracticing === registrationDetails?.engagedInPracticeOfMedicine);
    return workPostLevelList === undefined ? [] : workPostLevelList.map((item: WorkPostLevel) => {
      return {
        id: item.id,
        label: item.value,
        code: item.code
      }
    });
  }

  if (workpostStatus === ApiRequestStatus.Pending || saveWorkpostStatus === ApiRequestStatus.Pending
    || workpostListStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={4}>

          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <AppDatePicker control={control} name={StartDate.name} label={StartDate.label}
              minDate={latestWorkpost !== undefined ? addDays(new Date(latestWorkpost.startDate), 1) : null}
              maxDate={new Date()} />
          </Grid>

          <Grid item xs={12}>
            <AppAutocomplete control={control} name={PostLevel.name} label={PostLevel.label}
              items={populateWorkPostLevels()} information_text={PostLevel.information_text} />
          </Grid>

          <Grid item xs={12}>
            <AppTextInput control={control} name={JobTitle.name} label={JobTitle.label} />
          </Grid>

          <Grid item xs={12}>
            <AppAutocomplete control={control} name={Country.name}
              label={Country.label} items={MapCountriesToAutocompleteItems(countries ?? [])} />
          </Grid>

          <Grid item xs={12}>
            <AppAutocomplete control={control} name={NameOfEmployer.name} label={NameOfEmployer.label}
              items={MapHospitalsToAutocompleteItems(hospitals?.filter(x => x.countryId === getValues([Country.name]).toString()) ?? [])} />

            {(getValues(NameOfEmployer.name) === undefined || getValues(NameOfEmployer.name) === "") &&
              <Button variant="text" style={{ fontSize: '12px', color: 'blue', textDecoration: 'underline' }}
                onClick={() => setShowOtherNameOfEmployer(true)}>I cannot find my employer</Button>}
          </Grid>

          {showOtherNameOfEmployer === true && <Grid item xs={12}>
            <AppTextInput control={control} name={OtherNameOfEmployer.name} label={OtherNameOfEmployer.label} />
          </Grid>}

          <Grid item xs={12}>
            <AppTextInput control={control} name={NameOfClinicalSupervisor.name} label={NameOfClinicalSupervisor.label} />
          </Grid>

          <Grid item md={4} sm={6} xs={12}>
            {workpostList && workpostList?.length > 0 && <Button sx={{ "& .MuiButton-startIcon": { marginRight: "0px", marginLeft: '0' } }} variant="outlined"
              onClick={backToWorkDetailsClick}>Back to work details</Button>}
          </Grid>

          <Grid item md={8} sm={6} xs={12}>
            <Button sx={{ "& .MuiButton-startIcon": { marginRight: "0px", marginLeft: '0' } }} variant="contained"
              onClick={handleSave}>Save work details</Button>
          </Grid>

        </Grid>
      </Box>
    </>
  );
}
