import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import AppLabelField from "../../../../app/components/AppLabelField";
import { PcsPlanStatuses } from "../../common/PcsPlanStatuses";
import pcsPlanFormModel from "../../common/pcsPlanFormModel";
import { AppPathEnum } from "../../../../app/enums/appPathEnum";
import { useAppSelector } from "../../../../app/store/configureStore";
import { useState, useEffect } from "react";
import { PcsPdpStatus } from "../../../../app/models/staticData/pcsPdpStatus";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../../../app/utils/constant";

export function PcsPlanStatus() {
    const { AnnualPlanStatus } = pcsPlanFormModel.formField;

    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const { pcsPdpStatuses } = useAppSelector(state => state.ReferenceData);
    const { planStateStatus } = useAppSelector(state => state.pcsPdp);
    const { enrolmentYears } = useAppSelector(state => state.pcsEnrolment);

    const [pcsPdpStatus, setPcsPdpStatus] = useState<PcsPdpStatus>();

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0 && pcsPdpStatuses) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            setPcsPdpStatus(pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId));
        }
    }, [enrolmentYears, pcsPdpStatuses, enrolmentYearId]);

    if (planStateStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <Box sx={{ minWidth: "100%" }}>
        <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

            <Grid item xs={12} sm={12}>
                <Button component={Link} to={`${AppPathEnum.PCS_PLAN}`} variant="contained">
                    Back to Annual Plans
                </Button>
            </Grid>

            <Grid item xs={12} sm={12}>
                <Typography variant='h6'>PCS Plan</Typography>
            </Grid>

            <Grid item xs={12}>
                <AppLabelField value={pcsPdpStatus?.value} label={AnnualPlanStatus.label}
                    information_text={pcsPdpStatus?.code && PcsPlanStatuses(pcsPdpStatus?.code)} />
            </Grid>
        </Grid>
    </Box>
}