import { Box, Button, Grid, Typography } from "@mui/material";
import AppTextareaInput from "../../../../../app/components/AppTextareaInput";
import { useForm } from "react-hook-form";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/store/configureStore";
import { cancelPlanAsync, updatePlannedActivityAsync } from "../../../../../app/slices/pcsPdpSlice";
import { PcsPdpStatus } from "../../../../../app/models/staticData/pcsPdpStatus";
import { PdpStatusEnum } from "../../../models/pdpStatusEnum";
import { CancelPlanParameters } from "../../../models/cancelPlanParameters";
import { toast } from "react-toastify";
import { PlannedActivity } from "../../../models/plannedActivity";
import { yupResolver } from "@hookform/resolvers/yup";
import { plannedActivitiesValidationSchema } from "../../../common/pcsPlanValidationSchema";
import {
    API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_PLAN_CANCELED
} from "../../../../../app/utils/constant";
import { getPcsEnrolmentYearsAsync } from "../../../../../app/slices/pcsEnrolmentSlice";

interface Props {
    canUpdatePlannedActivity: boolean;
}

export function UpdatePlannedActivities({ canUpdatePlannedActivity }: Props) {
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(plannedActivitiesValidationSchema)
    });

    const { control, reset, getValues, trigger } = methods;
    const { External, WorkBasedLearning, PracticeReview } = pcsPlanFormModel.formField;

    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const [isCurrentYearPlan, setIsCurrentYearPlan] = useState<boolean>();
    const [canUpdatePlannedActivities, setCanUpdatePlannedActivities] = useState<boolean>();
    const [pcsPdpStatus, setPcsPdpStatus] = useState<PcsPdpStatus>();

    const dispatch = useAppDispatch();

    const { pcsPdpStatuses } = useAppSelector(state => state.ReferenceData);
    const { enrolmentYears } = useAppSelector(state => state.pcsEnrolment);
    const { currentYear } = useAppSelector(state => state.pcs);

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            reset({
                [External.name]: enrolmentYear?.pdpExternal,
                [WorkBasedLearning.name]: enrolmentYear?.pdpWorkBasedLearning,
                [PracticeReview.name]: enrolmentYear?.pdpPracticeReview,
            });
        }
    }, [enrolmentYears, reset]);

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0 && currentYear) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            setIsCurrentYearPlan(enrolmentYear?.year === currentYear);
        }
    }, [enrolmentYears, currentYear, reset]);

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0 && pcsPdpStatuses && currentYear) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            const pcsPdpStatus = pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId);

            setCanUpdatePlannedActivities((pcsPdpStatus?.code === PdpStatusEnum.Draft || pcsPdpStatus?.code === PdpStatusEnum.NotStarted)
                && enrolmentYear?.year === currentYear);
        }
    }, [enrolmentYears, pcsPdpStatuses, currentYear]);

    useEffect(() => {
        if (pcsPdpStatuses && enrolmentYears) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            setPcsPdpStatus(pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId));
        }
    }, [pcsPdpStatuses, enrolmentYears]);

    const cancelPlan = () => {
        let cancelPlanObject: CancelPlanParameters = { enrolmentYearId: enrolmentYearId };
        dispatch(cancelPlanAsync(cancelPlanObject)).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                dispatch(getPcsEnrolmentYearsAsync()).then((response) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        toast.success(MESSAGE_PLAN_CANCELED);
                    }
                });
            }
        });;
    }

    const savePlannedActivities = async (isSubmitted: boolean) => {
        const isStepValid = await trigger();

        if (isStepValid) {
            let plannedActivity = mapToPlannedActivitiesDto(isSubmitted);
            dispatch(updatePlannedActivityAsync(plannedActivity)).then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    dispatch(getPcsEnrolmentYearsAsync()).then((response) => {
                        if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                            toast.success(MESSAGE_DETAILS_SAVED);
                        }
                    });
                }
            });
        }
    };

    const mapToPlannedActivitiesDto = (isSubmitted: boolean) => {
        const data: PlannedActivity = {
            external: getValues([External.name]).toString(),
            workBasedLearning: getValues([WorkBasedLearning.name]).toString(),
            practiceReview: getValues([PracticeReview.name]).toString(),
            enrolmentYearId: enrolmentYearId,
            submit: isSubmitted
        }
        return data;
    }

    return <Box sx={{ width: '100%' }}>

        <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

            <Grid item xs={12}></Grid>

            <Grid item xs={12}>
                <Typography variant='h6'><b>Planned activities</b></Typography>
            </Grid>

            <Grid item xs={12}>
                <AppTextareaInput control={control} name={External.name}
                    label={External.label} disabled={!canUpdatePlannedActivity} />
            </Grid>

            <Grid item xs={12}>
                <AppTextareaInput control={control} name={WorkBasedLearning.name}
                    label={WorkBasedLearning.label} disabled={!canUpdatePlannedActivity} />
            </Grid>

            <Grid item xs={12}>
                <AppTextareaInput control={control} name={PracticeReview.name}
                    label={PracticeReview.label} disabled={!canUpdatePlannedActivity} />
            </Grid>

            {isCurrentYearPlan === true &&
                <>
                    {pcsPdpStatus?.code === PdpStatusEnum.ReviewRequestPending &&
                        <Grid item xs={4} sm={3} sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Button variant="contained" color="primary" onClick={() => cancelPlan()}
                                disabled={!canUpdatePlannedActivity}>
                                Cancel review request
                            </Button>
                        </Grid>
                    }

                    {canUpdatePlannedActivities === true &&
                        <>
                            <Grid item xs={7} sm={2} sx={{ display: 'flex', justifyContent: 'atsrt' }}>
                                <Button variant="contained" color="primary" onClick={() => savePlannedActivities(false)}
                                    disabled={!canUpdatePlannedActivity}>
                                    Save as a Draft
                                </Button>
                            </Grid>

                            <Grid item xs={0.5}></Grid>

                            <Grid item xs={4.5} sm={2} sx={{ display: 'flex', justifyContent: 'start' }}>
                                <Button variant="contained" color="primary" onClick={() => savePlannedActivities(true)}
                                    disabled={!canUpdatePlannedActivity}>
                                    Submit
                                </Button>
                            </Grid>
                        </>
                    }

                    <Grid item xs={1}></Grid>
                </>
            }
        </Grid>
    </Box>
}