const cpdCategoriesFormModel = {
  formId: 'CpdCategoriesForm',
  formField: {
    AccreditedCeActivity: {
      name: 'AccreditedCeActivity',
      label: 'Accredited CE Activity',
      informationText: 'External to the workplace - local, national, and international.'
    },
    ProfessionalDevelopmentPlan: {
      name: 'ProfessionalDevelopmentPlan',
      label: 'Professional Development Plan',
      informationText: 'A tool to capture your annual planned CPD activities. Refer to Professional Development Plan link on the portal home page.'
    },
    PracticeReview: {
      name: 'PracticeReview',
      label: 'Practice review',
      informationText: 'Audit, quality improvement, and / or practice evaluation.'
    },
    WorkbasedLearning: {
      name: 'WorkbasedLearning',
      label: 'Workbased learning',
      informationText: 'Reflective learning from clinical and non-clinical work.'
    },
    OnTarget: {
      name: 'OnTarget',
      label: 'On target',
      informationText: 'Indicates achievement of Medical council CPD target requirements.'
    },
  }
};

export default cpdCategoriesFormModel;