export const API_REQUEST_FULLFILLED = "fulfilled";
export const MIN_AGE_TO_ENROLL = 16;
export const MESSAGE_DETAILS_SAVED = "Details saved successfully.";
export const MESSAGE_DLETED_SAVED = "Details deleted successfully.";
export const MESSAGE_PLAN_CANCELED = "Plan canceled.";
export const MESSAGE_PROCESSING_DETAILS = "Processing details...";
export const MESSAGE_SAVE_AND_CONTINUE = "Save and continue";
export const MESSAGE_NEXT = "Next";
export const MESSAGE_SUBMIT = "Submit";
export const MESSAGE_REGISTER = "Register";
export const MESSAGE_BACK_TO_PREVIOUS_STEP = "Back to previous step";
export const MESSAGE_BACK_TO_DONATIONS = "Back to Donations";
export const MAX_FILE_SIZE = 5000000;
export const MESSAGE_FILE_UPLOADED_SCESSFULLY = "File uploaded successfully.";
export const MESSAGE_FILE_DELETED_SCESSFULLY = "File deleted successfully.";
export const MESSAGE_DETAILS_SENT_TO_REVIEWER = "Details sent to reviewer successfully.";
export const MESSAGE_NOT_A_VALUE = "##NOT_A_VALUE##";
export const NO_FURTHER_ACTION_REQUIRED = 'There are no further actions required at this time.';
export const UN_AUTHORISED_AS_USER_RATIFIED = 'Not authorised as user is Ratified.';
export const UN_AUTHORISED_AS_USER_CONFERRED = 'Not authorised as user is Conferred.';
export const UN_AUTHORISED_AS_USER_DECLINED = 'Not authorised as user is Declined.';
export const NOT_AUTHORISED_TO_ACCESS_THIS_AREA = 'Not authorised to access this area.';
export const SKIP_TO_UPLOAD = "SKIP_TO_UPLOAD";
export const DIRECT_DEBIT_EXISTING_DD_SUCCESS_RESPONSE = "Your payment will be taken using your existing Direct Debit with us.";
export const DIRECT_DEBIT_EXISTING_DD_ERROR_RESPONSE = "Error while setting up Direct Debit.";
export const EMAIL_EXPRESSION = /^(?=.{1,64}@.{4,48}$)([a-zA-Z0-9'_-]+([\.][a-zA-Z0-9'_-]+)*@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z0-9]+([-]+[a-zA-Z0-9]+)*\.)+(?=.{2,})([a-zA-Z]+([-]*[a-zA-Z]+)*))))$/