import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: '#DE1834',
        color: 'white',
        alignItems: 'center',
        textAlign: 'center',
        height: 40,
        fontSize: 9
    },
    PcsYear: {
        width: '50%',
        textAlign: 'center',
        fontStyle: 'bold'
    },
    EngagedInPracticeOfMedicine: {
        paddingLeft: '2px',
        width: '45%',
        textAlign: 'center',
        fontStyle: 'bold'
    },
    AccreditedCeActivity: {
        paddingLeft: '2px',
        width: '50%',
        fontStyle: 'bold',
        textAlign: 'center'
    },
    PracticeReview: {
        width: '50%',
        fontStyle: 'bold',
        textAlign: 'center'
    },
    ProfessionalDevelopmentPlan: {
        paddingLeft: '2px',
        width: '45%',
        fontStyle: 'bold',
        textAlign: 'center'
    },
    WorkbasedLearning: {
        paddingLeft: '2px',
        width: '45%',
        fontStyle: 'bold',
        textAlign: 'center'
    },
    OnTarget: {
        width: '30%',
        fontStyle: 'bold',
        textAlign: 'center'
    },
    VerificationStatus: {
        width: '40%',
        fontStyle: 'bold',
        textAlign: 'center',
        paddingLeft: '7px'
    }
});

const ReceiptTableHeader = () => {
    return (
        <View style={styles.container}>
            <Text style={styles.PcsYear}>PCS year</Text>
            <Text style={styles.EngagedInPracticeOfMedicine}>Engaged in practice of medicine</Text>
            <Text style={styles.AccreditedCeActivity}>Accredited CE Activity</Text>
            <Text style={styles.ProfessionalDevelopmentPlan}>Professional Development Plan</Text>
            <Text style={styles.PracticeReview}>Practice review</Text>
            <Text style={styles.WorkbasedLearning}>Workbased learning</Text>
            <Text style={styles.OnTarget}>On target</Text>
            <Text style={styles.VerificationStatus}>Verification status</Text>
        </View>
    )
};

export default ReceiptTableHeader;