import { Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { GetSummaryColorCode } from "../../../../app/utils/util";
import AppInformationText from "../../../../app/components/AppInformationText";
import { CreditSummaryHelpText } from "../../common/CreditSummaryHelpText";
import { SummaryDto } from "../../../../app/models/summary/summaryDto";
import { useAppSelector } from "../../../../app/store/configureStore";
import { useEffect, useState } from "react";
import cpdCategoriesFormModel from "../../../pcsCpd/common/cpdCategoriesFormModel";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiPaper-root": {
            width: "unset",
            marginLeft: "0"
        }
    },
    alignCenter: {
        textAlign: 'center'
    }
}));

export function CreditSummary() {
    const {
        formField: {
            AccreditedCeActivity,
            ProfessionalDevelopmentPlan,
            PracticeReview,
            WorkbasedLearning,
            OnTarget
        }
    } = cpdCategoriesFormModel;

    const classes = useStyles();
    const { currentYear, summaryData } = useAppSelector(state => state.pcs);

    const [creditSummarydata, setCreditSummaryData] = useState<SummaryDto[] | null>(null);

    useEffect(() => {
        if (summaryData && currentYear !== null) {
            summaryData.map((item: SummaryDto[]) => {
                if (item[0].text.substring(0, 4) === (currentYear - 1).toString()) {
                    setCreditSummaryData(item);
                }
            });
        }
    }, [summaryData, currentYear]);

    return (
        <>
            <div className={classes.root}>
                <Grid container rowSpacing={4}>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={6} md={2} sm={3}>
                        <Typography variant='h6'><b>Credit summary</b></Typography>
                    </Grid>

                    <Grid item xs={1} md={1} sm={1} sx={{ marginTop: '-7px' }}>
                        <AppInformationText information_text={CreditSummaryHelpText()} placement={"top"} />
                    </Grid>

                    <Grid item xs={5} md={9} sm={8}></Grid>
                </Grid>

                <Box sx={{ flexGrow: 1, backgroundColor: "#616161", minWidth: "650px" }} component={Paper}>
                    <Grid container component={Paper} sx={{
                        backgroundColor: '#616161',
                        color: "#fff",
                        padding: 2,
                        textAlign: 'left',
                        width: '100%',
                        minWidth: '1100px'
                    }}>
                        <Grid item xs={1} className={classes.alignCenter}>
                            PCS Year
                        </Grid>

                        <Grid item xs={2} className={classes.alignCenter}>
                            Engaged in  Parctice of Medicine
                        </Grid>

                        <Grid item xs={1.5} className={classes.alignCenter}>
                            <b>{AccreditedCeActivity.label}</b>
                        </Grid>

                        <Grid item xs={1.5} className={classes.alignCenter}>
                            <b>{ProfessionalDevelopmentPlan.label}</b>
                        </Grid>

                        <Grid item xs={1.5} className={classes.alignCenter}>
                            <b>{PracticeReview.label}</b>
                        </Grid>

                        <Grid item xs={1.5} className={classes.alignCenter}>
                            <b>{WorkbasedLearning.label}</b>
                        </Grid>

                        <Grid item xs={1.5} className={classes.alignCenter}>
                            <b>{OnTarget.label}</b>
                        </Grid>

                        <Grid item xs={1.5} className={classes.alignCenter}>
                            Verification Status
                        </Grid>
                    </Grid>


                    <Grid container component={Paper}
                        sx={{
                            textAlign: 'center',
                            padding: 1,
                            width: '100%',
                            minWidth: '1100px'
                        }}
                    >
                        <Grid item xs={1} sx={{
                            color: 'primary.main',
                            paddingTop: 2
                        }}>
                            {creditSummarydata && creditSummarydata[0].text}
                            {creditSummarydata && creditSummarydata[2].text.toLowerCase() === "true" ? "*" : ""}
                        </Grid>

                        <Grid item xs={2} sx={{ padding: 2 }}>
                            {creditSummarydata && creditSummarydata[1].text}
                        </Grid>

                        <Grid item xs={1.5} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: creditSummarydata && GetSummaryColorCode(creditSummarydata[3].status.toUpperCase()),
                                textAlign: 'center',
                                padding: 1
                            }}>
                                <b>{creditSummarydata && creditSummarydata[3].text.split('(')[0]}</b>({creditSummarydata && creditSummarydata[3].text.split('(')[1]}
                            </Grid>
                        </Grid>

                        <Grid item xs={1.5} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: creditSummarydata && GetSummaryColorCode(creditSummarydata[4].status?.toUpperCase()),
                                textAlign: 'center',
                                padding: 1
                            }}>
                                <b>{creditSummarydata && creditSummarydata[4].text.split('(')[0]}</b>({creditSummarydata && creditSummarydata[4].text.split('(')[1]}
                            </Grid>
                        </Grid>

                        <Grid item xs={1.5} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: creditSummarydata && GetSummaryColorCode(creditSummarydata[5].status?.toUpperCase()),
                                textAlign: 'center',
                                padding: 1
                            }}>
                                <b>{creditSummarydata && creditSummarydata[5].text.split('(')[0]}</b>({creditSummarydata && creditSummarydata[5].text.split('(')[1]}
                            </Grid>
                        </Grid>

                        <Grid item xs={1.5} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: creditSummarydata && GetSummaryColorCode(creditSummarydata[6].status?.toUpperCase()),
                                textAlign: 'center',
                                padding: 1
                            }}>
                                <b>{creditSummarydata && creditSummarydata[6].text.split('(')[0]}</b>({creditSummarydata && creditSummarydata[6].text.split('(')[1]}
                            </Grid>
                        </Grid>

                        <Grid item xs={1.5} sx={{ padding: 1 }}>
                            <Grid sx={{
                                backgroundColor: creditSummarydata && GetSummaryColorCode(creditSummarydata[7].status?.toUpperCase()),
                                textAlign: 'center',
                                padding: 1
                            }}>
                                <b>{creditSummarydata && creditSummarydata[7].text.split('(')[0]}</b>({creditSummarydata && creditSummarydata[7].text.split('(')[1]}
                            </Grid>
                        </Grid>

                        <Grid item xs={1.5} sx={{
                            paddingTop: 2
                        }}>
                            {creditSummarydata && creditSummarydata[8].text}
                        </Grid>
                    </Grid>
                </Box>
            </div >
        </>
    )
}