import { Text, View, StyleSheet, Image, Line, Svg } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        textAlign: 'left',
        height: 'auto',
        fontSize: 9,
        alignItems: 'center'
    },
    pcsHeaderLeft: {
        width: '15%',
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold'
    },
    logo: {
        width: 38,
        marginLeft: '250px',
        alignItems: 'flex-end'
    },
    pcsHeaderRight: {
        width: '70%',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        alignItems: 'flex-end',
        marginLeft: '50px',
        fontSize: 7
    }
});

const ReceiptHeader = () => {
    return (
        <>
            <View style={styles.row}>
                <Text style={styles.pcsHeaderLeft}>
                    Professional Competence Scheme
                </Text>

                <Image style={styles.logo} src={`${String(process.env.REACT_APP_STORAGE_ACCOUNT_URL)}/images/rcsi-logo-png.png`} />

                <Text style={{ marginLeft: '30px', marginTop: '-30px' }}>
                    <Text style={styles.pcsHeaderRight}>
                        Professional Competence Scheme {"\n"}
                    </Text>

                    <Text style={{ fontWeight: 100 }}>
                        RCSI
                    </Text>
                </Text>
            </View >

            <Svg height="5" width="525">
                <Line x1="0" y1="5" x2="525" y2="5" stroke="rgb(0,0,0)" />
            </Svg>
        </>
    )
};

export default ReceiptHeader;