import { Box, Paper, Grid, Typography } from "@mui/material";

interface Prop {
    reenrol: boolean;
}

export default function Header({ reenrol }: Prop) {

    return <>
        <Box sx={{ width: '100%' }} component={Paper}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginTop: '2%' }}>
                    <Typography variant='h4'>
                        {reenrol === true ? 'Professional Competence Scheme Re-enrolment' :
                            'Professional Competence Scheme Enrolment'}
                    </Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        Please enter your details below. Further information on Professional
                        Competence Guidelines can be found on relevant schemes website.
                    </Typography>
                </Grid>

            </Grid>
        </Box>
    </>
}
