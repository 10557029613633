import { Box, Grid, Typography } from "@mui/material";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import AppLabelField from "../../../../../app/components/AppLabelField";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../../app/store/configureStore";

export function ViewFeedback() {
    const { ReviewerComments, PractionerComments } = pcsPlanFormModel.formField;

    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();
    const { enrolmentYears } = useAppSelector(state => state.pcsEnrolment);

    return <>
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}>
                    <Typography variant='h6'>Feedback</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppLabelField value={enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId)?.pdpReviewerComments ?? ''}
                        label={ReviewerComments.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppLabelField value={enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId)?.pdpRevieweeComments ?? ''}
                        label={PractionerComments.label} />
                </Grid>

            </Grid>
        </Box>
    </>
}