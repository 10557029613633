import * as yup from 'yup';
import "yup-phone-lite";
import { getFileExtension } from '../../../app/utils/util';
import { MAX_FILE_SIZE, SKIP_TO_UPLOAD } from '../../../app/utils/constant';
import cpdFormModel from './cpdFormModel';

const {
  formField: {
    SupportingDocument
  }
} = cpdFormModel;

export const cpdAddDocumentValidationSchema = yup.object().shape({
  [SupportingDocument.name]: yup.mixed()
    .test({
      message: SupportingDocument.requiredErrorMsg,
      test: (file: any) => {
        return file !== undefined;
      }
    })
    .test({
      message: SupportingDocument.invalidFileTypeErrorMsg,
      test: (file: any, context) => {
        if (file === SKIP_TO_UPLOAD) return true;

        const extension = getFileExtension(file ? file?.name : '');
        if (extension !== undefined) {
          const isValid = file && ['doc', 'docx', 'pdf', 'txt', 'text', 'xls', 'xlsx', 'jpg', 'jpeg',
            'png', 'gif', 'ppt', 'pps', 'pptx', 'ppsx'].includes(extension);
          if (!isValid) context?.createError();

          return isValid;
        }
        context?.createError();
      }
    })
    .test({
      message: SupportingDocument.invalidFileSizeErrorMsg,
      test: (file: any) => {
        if (file === SKIP_TO_UPLOAD) return true;

        const isValid = file?.size < MAX_FILE_SIZE;
        return isValid;
      }
    })
});