import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDateWithMonthName } from '../../../../app/utils/util';
import { CreditRecord } from '../../models/creditRecord';
import { AppPathEnum } from '../../../../app/enums/appPathEnum';
import { useAppSelector } from '../../../../app/store/configureStore';

export default function CpdDataList() {

  const { cpdCategories, activityTypes, cpdRecordStatuses } = useAppSelector(state => state.ReferenceData);
  const { creditRecords, documents } = useAppSelector(state => state.credits);

  return (
    <>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell><b>Date of activity</b></TableCell>
                  <TableCell align="left"><b>Category</b></TableCell>
                  <TableCell align="left"><b>Activity type</b></TableCell>
                  <TableCell align="left"><b>Activity description</b></TableCell>
                  <TableCell align="left"><b>Credits</b></TableCell>
                  <TableCell align="left"><b>Status</b></TableCell>
                  <TableCell align="left"><b>No. of documents attached</b></TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {creditRecords?.map((row: CreditRecord) => (
                  <TableRow
                    key={row.creditRecordId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      {new Date(row.activityDate).getFullYear() !== 1 ?
                        formatDateWithMonthName(new Date(row.activityDate)) :
                        ''}
                    </TableCell>
                    <TableCell align="left">
                      {cpdCategories?.find(x => x.id === row.cpdCategoryId)?.value}
                    </TableCell>
                    <TableCell align="left">
                      {activityTypes?.find(x => x.id === row.activityTypeId)?.value}
                    </TableCell>
                    <TableCell align="left">{row.description}</TableCell>
                    <TableCell align="left">{row.credits}</TableCell>
                    <TableCell align="left">
                      {cpdRecordStatuses?.find(x => x.id === row.cpdRecordStatusId)?.value}
                    </TableCell>
                    <TableCell align="left">
                      {documents?.filter(x => x.directory === row.creditRecordId).length}
                    </TableCell>
                    <TableCell align="left">
                      <Button component={Link} to={`${AppPathEnum.PCS_VIEW_CPD}/${row.creditRecordId}`} size="small">View</Button>
                    </TableCell>
                  </TableRow>
                ))}
                {creditRecords?.length === 0 &&
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No data found
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

      </Grid>
    </>
  );
}