import { Box, Grid, Typography } from "@mui/material";
import { formatDateWithMonthName } from "../../../../app/utils/util";
import { useGetWorkpostList } from "../../../../app/customHooks/useGetWorkpostList";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useAppSelector } from "../../../../app/store/configureStore";
import { MESSAGE_PROCESSING_DETAILS } from "../../../../app/utils/constant";
import { useReferenceData } from "../../../../app/customHooks/useReferenceData";

export function WorkDetails() {
    const { workPostLevels, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { workpostList, workpostListStatus } = useAppSelector(state => state.workpost);

    useReferenceData();
    useGetWorkpostList();

    if (staticDataStateStatus === ApiRequestStatus.Pending || workpostListStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Box sx={{ width: '100%', backgroundColor: '#e7e7e7', padding: '2%' }}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12}>
                    <Typography variant='h6'>Current work details</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='subtitle2'>To Update work details please go to 'My profile' on the portal</Typography>
                </Grid>

                <Grid item xs={5} sm={4}>
                    Start date
                </Grid>

                <Grid item xs={7} sm={8}>
                    {(workpostList && workpostList[0].startDate === null || workpostList && workpostList[0].startDate === undefined) ? "" :
                        workpostList && new Date(workpostList[0].startDate).getFullYear() === 0 ? "" :
                            workpostList && formatDateWithMonthName(new Date(workpostList[0].startDate))}
                </Grid>

                <Grid item xs={5} sm={4}>
                    Post Level
                </Grid>

                <Grid item xs={7} sm={8}>
                    {workpostList && (workpostList[0].workPostLevelId === undefined ? "" : workPostLevels?.find(x => x.id === workpostList[0].workPostLevelId)?.value)}
                </Grid>

                <Grid item xs={5} sm={4}>
                    Name of employer
                </Grid>

                <Grid item xs={7} sm={8}>
                    {workpostList && workpostList[0].employerId}
                </Grid>

            </Grid>
        </Box>
    </>
}