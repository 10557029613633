import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import AppFileInput from "../../../../app/components/AppFileInput";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API_REQUEST_FULLFILLED, MESSAGE_FILE_UPLOADED_SCESSFULLY, MESSAGE_PROCESSING_DETAILS } from "../../../../app/utils/constant";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { addDocumentAsync, getDocumentsByCreditRecordIdAsync } from "../../../../app/slices/creditsSlice";
import { useParams } from "react-router-dom";
import cpdFormModel from "../../common/cpdFormModel";
import { cpdAddDocumentValidationSchema } from "../../common/cpdAddDocumentValidationSchema";
import UnsavedChangesDialog from "../../../../app/components/UnsavedChangesDialog";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";

export function AddSupportingDocument() {
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(cpdAddDocumentValidationSchema)
    });
    const { control, watch, trigger, getValues, setValue } = methods;
    const formObj = methods.watch();
    const { creditRecordId } = useParams<{ creditRecordId: string }>();

    const {
        formField: {
            SupportingDocument
        }
    } = cpdFormModel;

    const dispatch = useAppDispatch();
    const { documentsByCreditRecordId, addDocumentStatus } = useAppSelector(state => state.credits);

    const [isDocumentUploadInProgress, setIsDocumentUploadInProgress] = useState(false);

    const populateFileFormData = (file: File) => {
        const formData = new FormData();
        formData.append("CreditRecordId", creditRecordId);
        formData.append("File", file);

        return formData;
    }

    useEffect(() => {
        watch((_, { name, type }) => {
            if (name === SupportingDocument.name && type === 'change') {
                setIsDocumentUploadInProgress(true);
            }
        });
    }, [SupportingDocument.name, watch]);

    const addCpdDocumentAsync = async () => {
        const isStepValid = await trigger();

        if (isStepValid === true) {
            dispatch(addDocumentAsync(populateFileFormData(formObj.SupportingDocument as File))).then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    setIsDocumentUploadInProgress(false);
                    dispatch(getDocumentsByCreditRecordIdAsync(creditRecordId))
                    toast.success(MESSAGE_FILE_UPLOADED_SCESSFULLY);
                };
            });
        }
    }

    const cpdDocumentUploadFilter = () => {
        return getValues([SupportingDocument.name])[0] && documentsByCreditRecordId &&
            (documentsByCreditRecordId?.filter(x => x.name?.trim() === (getValues([SupportingDocument.name])[0]).name.trim()).length > 0);
    }

    if (addDocumentStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <FormProvider {...methods}>
        <Grid container rowSpacing={4}>
            <Grid item xs={12}></Grid>

            <Grid item xs={3}>
                Supporting Document*
            </Grid>

            <Grid item xs={3}>
                <AppFileInput control={control} label={""} name={SupportingDocument.name} />
            </Grid>

            <Grid item xs={6}></Grid>

            <Grid item xs={3}></Grid>

            <Grid item xs={9}>
                <LoadingButton variant="contained" component="span" onClick={addCpdDocumentAsync}>
                    Add This Document
                </LoadingButton>
            </Grid>

        </Grid>

        {isDocumentUploadInProgress === true && cpdDocumentUploadFilter() === true &&
            <UnsavedChangesDialog showDialog={true}
                title={'Duplicate file found.'}
                subTitle={`The file you are uploading is already in the system. 
                    Please rename the file and upload again.`}
                skipButtonClick={() => {
                    setValue(SupportingDocument.name, undefined);
                    setIsDocumentUploadInProgress(false);
                    trigger();
                }}
                showYesNoButtons={false} />
        }
    </FormProvider>
}