import { WorkDetails } from "./WorkDetails";
import { useParams } from "react-router-dom";
import { ActivitiesAcrossDomains } from "./ActivitiesAcrossDomains";
import { CreditSummary } from "./CreditSummary";
import { useAppSelector } from "../../../../app/store/configureStore";
import { useGetActivitiesAcrossDomains } from "../../../../app/customHooks/useGetActivitiesAcrossDomains";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../../../app/utils/constant";
import { PcsPlanStatus } from "./PcsPlanStatus";
import { useGetCreditSummary } from "../../../../app/customHooks/useGetCreditSummary";

export function ActivityDetails() {
    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const { activitiesAcrossDomains, planStateStatus } = useAppSelector(state => state.pcsPdp);
    const { enrolmentYears } = useAppSelector(state => state.pcsEnrolment);
    const { currentYear, summaryData } = useAppSelector(state => state.pcs);

    useGetActivitiesAcrossDomains(true);
    useGetCreditSummary(true);

    if (planStateStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <PcsPlanStatus />

        {enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId)?.year === currentYear &&
            <>
                <WorkDetails />

                {summaryData && summaryData?.length > 0 && <CreditSummary />}

                {activitiesAcrossDomains?.summaryData?.length !== 0 && <ActivitiesAcrossDomains />}
            </>
        }
    </>
}