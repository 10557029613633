import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { deleteDocumentAsync, getDocumentAsync, getDocumentsByCreditRecordIdAsync } from "../../../../app/slices/creditsSlice";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS } from "../../../../app/utils/constant";
import { formatBytes, formatDateWithMonthName } from "../../../../app/utils/util";
import { FileDto } from "../../../../app/models/membership/fileDto";
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import UnsavedChangesDialog from "../../../../app/components/UnsavedChangesDialog";
import { CpdStatus } from "../../../../app/models/staticData/cpdStatus";

export function SupportingDocuments() {
    const { creditRecordId } = useParams<{ creditRecordId: string }>();

    const dispatch = useAppDispatch();
    const { cpdRecordStatuses } = useAppSelector(state => state.ReferenceData);
    const { cpdDetails, documentsByCreditRecordId, getDocumentStatus, getDocumentsByCreditRecordIdStatus, deleteDocumentStatus } = useAppSelector(state => state.credits);
    const [deleteCpdDocumentConfirmation, setDeleteCpdDocumentConfirmation] = useState(false);
    const [fileToDelete, setFileToDelete] = useState('');
    const [cpdRecordStatus, setCpdRecordStatus] = useState<CpdStatus>();

    useEffect(() => {
        if (cpdDetails !== null && cpdRecordStatuses && cpdRecordStatuses.length > 0) {
            setCpdRecordStatus(cpdRecordStatuses.find(x => x.id === cpdDetails.cpdRecordStatusId));
        }
    }, [cpdDetails, cpdRecordStatuses]);

    useEffect(() => {
        dispatch(getDocumentsByCreditRecordIdAsync(creditRecordId));
    }, [dispatch]);

    const downloadFile = (fileName: string | null) => {
        if (fileName !== null) {
            dispatch(getDocumentAsync({ creditRecordId, fileName })).then((response: any) => {
                const url = window.URL.createObjectURL(new Blob([response.payload], { type: 'application/octet-stream' }));
                const link = document.createElement('a');

                link.href = url;
                link.download = response.meta.arg.fileName;

                document.body.appendChild(link);

                link.click();
                link.remove();

                window.URL.revokeObjectURL(url);
            });
        }
    }

    const deleteDocument = (fileName: string) => {
        dispatch(deleteDocumentAsync({ creditRecordId: creditRecordId, fileName: fileName }))
            .then((response) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    dispatch(getDocumentsByCreditRecordIdAsync(creditRecordId));
                }
            });
    }

    if (getDocumentsByCreditRecordIdStatus === ApiRequestStatus.Pending ||
        getDocumentStatus === ApiRequestStatus.Pending ||
        deleteDocumentStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Grid container rowSpacing={4}>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>File name</b></TableCell>
                                <TableCell align="left"><b>Size</b></TableCell>
                                <TableCell align="left"><b>Last modified</b></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {documentsByCreditRecordId?.map((row: FileDto, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.size && row?.size !== null && formatBytes(row.size)}
                                    </TableCell>
                                    <TableCell>
                                        {row.modifiedDate && (new Date(row.modifiedDate).getFullYear() !== 1 ?
                                            formatDateWithMonthName(new Date(row.modifiedDate)) :
                                            '')}
                                    </TableCell>
                                    <TableCell align="left">
                                        <Button sx={{ "& .MuiButton-startIcon": { marginRight: "0px" } }} variant="outlined" startIcon={<DownloadIcon />}
                                            onClick={() => row?.name && downloadFile(row?.name)} >
                                            Download
                                        </Button>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Button sx={{ "& .MuiButton-startIcon": { marginRight: "0px" } }} variant="outlined"
                                            startIcon={<DeleteIcon />} onClick={() => {
                                                row?.name && setFileToDelete(row?.name);
                                                setDeleteCpdDocumentConfirmation(true)
                                            }}
                                            disabled={cpdRecordStatus && !cpdRecordStatus.userEditable}>
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {documentsByCreditRecordId?.length === 0 &&
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        No data found
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>

        {deleteCpdDocumentConfirmation &&
            <UnsavedChangesDialog showDialog={true}
                title={'Are you sure you want to delete ' + fileToDelete + '?'}
                subTitle={`You will loose the changes on clicking "YES".`}
                yesButtonClick={() => {
                    fileToDelete !== '' && deleteDocument(fileToDelete);
                    setDeleteCpdDocumentConfirmation(false);
                }}
                noButtonClick={() => setDeleteCpdDocumentConfirmation(false)}
                showYesNoButtons={true} />}
    </>
}