import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AppTextInput from "../../../../../app/components/AppTextInput";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import { MapTitlesToAutocompleteItems } from "../../../../../app/utils/convertStaticDataToDropdownItems";
import { useAppDispatch, useAppSelector } from "../../../../../app/store/configureStore";
import { ApiRequestStatus } from "../../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { confirmReviewerDetailsAsync, searchReviewerByImcNumberAsync, submitReviewerDetailsAsync } from "../../../../../app/slices/pcsPdpSlice";
import { LoadingButton } from "@mui/lab";
import { ViewReviewer } from "../viewPlan/ViewReviewer";
import { ConfirmReviewerDetailsParameters } from "../../../models/confirmReviewerDetailsParameters";
import { API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SENT_TO_REVIEWER, MESSAGE_PROCESSING_DETAILS } from "../../../../../app/utils/constant";
import { toast } from "react-toastify";
import { Link, useParams, useHistory } from "react-router-dom";
import { ReviewerDetails } from "../../../models/reviewerDetails";
import { yupResolver } from "@hookform/resolvers/yup";
import { reviewerDetailsValidationSchema } from "../../../common/pcsPlanValidationSchema";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../../../../app/enums/userClaims";
import { useReferenceData } from "../../../../../app/customHooks/useReferenceData";
import { getPcsEnrolmentYearsAsync } from "../../../../../app/slices/pcsEnrolmentSlice";
import { AppPathEnum } from "../../../../../app/enums/appPathEnum";
import AppAutocomplete from "../../../../../app/components/AppAutocomplete";

export default function ReviewerDetailsForm() {

    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const { Title, FirstName, Surname, IMCNumber, EmailAddress } = pcsPlanFormModel.formField;
    const { control, getValues, trigger } = useForm({
        resolver: yupResolver(reviewerDetailsValidationSchema)
    });

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const [isReviewerFound, setIsReviewerFound] = useState(false);
    const [isReviewerSearched, setIsReviewerSearched] = useState(false);
    const history = useHistory();

    const dispatch = useAppDispatch();

    const { titles, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { reviewerDetails, planStateStatus } = useAppSelector(state => state.pcsPdp);

    useReferenceData();

    const searchButtonClick = () => {
        dispatch(searchReviewerByImcNumberAsync(getValues(IMCNumber.name)));
    }

    useEffect(() => {
        if (reviewerDetails) {
            setIsReviewerSearched(true);
            setIsReviewerFound(true);
        }
        else if (reviewerDetails === "") {
            setIsReviewerSearched(true);
            setIsReviewerFound(false);
        }
    }, [reviewerDetails]);

    const confirmReviewer = async () => {
        let pcsPlanDetailsParameters: ConfirmReviewerDetailsParameters = {
            reviewerPersonId: personId,
            enrolmentYearId: enrolmentYearId
        };
        dispatch(confirmReviewerDetailsAsync(pcsPlanDetailsParameters)).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                dispatch(getPcsEnrolmentYearsAsync()).then((response) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        history.push(`${AppPathEnum.PCS_PLAN_DETAILS}/${enrolmentYearId}${window.location.search}`);
                        toast.success(MESSAGE_DETAILS_SENT_TO_REVIEWER);
                    }
                });
            }
        });
    }

    const submitReviewer = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            let reviewerDetails = mapToReviewerDetailsDto();
            dispatch(submitReviewerDetailsAsync(reviewerDetails)).then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    dispatch(getPcsEnrolmentYearsAsync()).then((response) => {
                        if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                            history.push(`${AppPathEnum.PCS_PLAN_DETAILS}/${enrolmentYearId}${window.location.search}`);
                            toast.success(MESSAGE_DETAILS_SENT_TO_REVIEWER);
                        }
                    });
                }
            });
        }
    }

    const mapToReviewerDetailsDto = () => {
        const data: ReviewerDetails = {
            enrolmentYearId: enrolmentYearId,
            titleId: getValues([Title.name]).toString(),
            firstName: getValues([FirstName.name]).toString(),
            surname: getValues([Surname.name]).toString(),
            imcNumber: getValues([IMCNumber.name]).toString(),
            email: getValues([EmailAddress.name]).toString()
        }
        return data;
    }

    const userFoundFilter = () => {
        return getValues([IMCNumber.name]).toString() !== "" &&
            isReviewerSearched === true && isReviewerFound === true;
    }

    const userNotFoundFilter = () => {
        return getValues([IMCNumber.name]).toString() !== "" &&
            isReviewerSearched === true && isReviewerFound === false;
    }

    if (staticDataStateStatus === ApiRequestStatus.Pending
        || planStateStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left' }}>
                    <Typography variant='h5'>Find reviewer</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='subtitle1'>
                        Please search our database by entering the IMC number of the reviewer below.
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <AppTextInput
                        control={control}
                        label={IMCNumber.label}
                        name={IMCNumber.name}
                    />
                </Grid>

                <Grid item xs={3}>
                    <LoadingButton
                        loading={false}
                        variant="contained"
                        type='button'
                        onClick={searchButtonClick}
                        sx={{ mt: 1, height: 'auto' }}
                    >
                        Search
                    </LoadingButton>
                </Grid>

                <Grid item xs={5}></Grid>

                {userNotFoundFilter() === true &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>
                                <div style={{ color: 'primary.main' }}>Please note that the reviewer's IMC number was not found in our database.</div>
                                Please complete the below fields and click submit. The PCS office will
                                validate these details and send the request on your behalf.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <AppAutocomplete
                                control={control}
                                label={Title.label}
                                name={Title.name}
                                items={MapTitlesToAutocompleteItems(titles ?? [])} />
                        </Grid>

                        <Grid item xs={12}>
                            <AppTextInput
                                control={control}
                                label={FirstName.label}
                                name={FirstName.name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <AppTextInput
                                control={control}
                                label={Surname.label}
                                name={Surname.name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <AppTextInput
                                control={control}
                                label={IMCNumber.label}
                                name={IMCNumber.name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <AppTextInput
                                control={control}
                                label={EmailAddress.label}
                                name={EmailAddress.name}
                            />
                        </Grid>
                    </>
                }

                {userFoundFilter() === true &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>
                                Please see below reviewer details. Please note that requests
                                will be sent to the email address we have on our records.
                                To proceed with this request click confirm.
                            </Typography>
                        </Grid>

                        <ViewReviewer />

                    </>
                }

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={3} sm={2} sx={{ display: 'flex', justifyContent: 'start' }}>
                    <Button component={Link} to={`${AppPathEnum.PCS_PLAN_DETAILS}/${enrolmentYearId}`}>
                        Cancel
                    </Button>
                </Grid>

                {userFoundFilter() === true &&
                    <Grid item xs={9} sm={3} sx={{ display: 'flex', justifyContent: 'start' }}>
                        <Button variant="contained" color="primary" onClick={() => confirmReviewer()}>
                            Confirm
                        </Button>
                    </Grid>}

                {userNotFoundFilter() === true &&
                    <Grid item xs={9} sm={3} sx={{ display: 'flex', justifyContent: 'start' }}>
                        <Button variant="contained" color="primary" onClick={() => submitReviewer()}>
                            Submit
                        </Button>
                    </Grid>
                }

            </Grid>
        </Box>
    </>
}