import { Box, Paper, Typography } from "@mui/material";
import { RecordStatusHistory } from "./RecordStatusHistory";
import { SupportingDocuments } from "./SupportingDocuments";
import { ViewCpdRecord } from "./ViewCpdRecord";
import { AddSupportingDocument } from "./AddSupportingDocument";
import { useState, useEffect } from "react";
import { CpdStatus } from "../../../../app/models/staticData/cpdStatus";
import { useParams } from "react-router-dom";
import { getCpdDetailsAsync } from "../../../../app/slices/creditsSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";

export default function ViewCpdDetails() {

    const { creditRecordId } = useParams<{ creditRecordId: string }>();

    const dispatch = useAppDispatch();

    const { cpdRecordStatuses } = useAppSelector(state => state.ReferenceData);
    const { cpdDetails } = useAppSelector(state => state.credits);
    const [cpdRecordStatus, setCpdRecordStatus] = useState<CpdStatus>();

    useEffect(() => {
        if (creditRecordId !== "") {
            dispatch(getCpdDetailsAsync(creditRecordId));
        }
    }, [creditRecordId, dispatch]);

    useEffect(() => {
        if (cpdDetails !== null && cpdRecordStatuses && cpdRecordStatuses.length > 0) {
            setCpdRecordStatus(cpdRecordStatuses.find(x => x.id === cpdDetails.cpdRecordStatusId));
        }
    }, [cpdDetails, cpdRecordStatuses]);

    return <>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Box sx={{ width: '100%' }}>
                <ViewCpdRecord />
            </Box>
        </Paper>

        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography variant='h4'>Supporting documents</Typography>
            <Box sx={{ width: '100%' }}>
                <SupportingDocuments />
            </Box>
        </Paper>

        {cpdRecordStatus && cpdRecordStatus.userEditable &&
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography variant='h4'>Add supporting document</Typography>
                <Box sx={{ width: '100%' }}>
                    <AddSupportingDocument />
                </Box>
            </Paper>}

        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Typography variant='h4'>Status history</Typography>
            <Box sx={{ width: '100%' }}>
                <RecordStatusHistory />
            </Box>
        </Paper>
    </>
}
