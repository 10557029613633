import { Box, Grid, Paper, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import AppTextInput from "../../../app/components/AppTextInput";
import { useEffect } from "react";
import { alumniFilter, famFilter, minEnrolmentYear } from "../../../app/utils/util";
import {
    MapAreaOfPracticesToAutocompleteItems, MapDisciplinesToAutocompleteItems,
    MapGendersToAutocompleteItems, MapTitlesToAutocompleteItems
} from "../../../app/utils/convertStaticDataToDropdownItems";
import AppDatePicker from "../../../app/components/AppDatePicker";
import MembershipFormModel from "../../../app/models/membership/membershipFormModel";
import { MESSAGE_NOT_A_VALUE } from "../../../app/utils/constant";
import { useMsal } from "@azure/msal-react";
import { IdTokenClaims } from "../../../app/models/account/idTokenClaims";
import { useAppSelector } from "../../../app/store/configureStore";
import AppAutocomplete from "../../../app/components/AppAutocomplete";
import { MapCountriesToAutocompleteItems } from "../../../app/utils/convertStaticDataToAutocompleteItems";

export function PersonalDetails() {
    const {
        Title,
        FirstName,
        MiddleName,
        Surname,
        Preferredname,
        DateOfBirth,
        CountryOfNationality,
        Gender,
        PreferredEmail1,
        AlternateEmail2,
        AlternateEmail3,
        PreferredPhoneNumber,
        AlternatePhoneNumber,
        LinkedInUrl,
        Discipline,
        AreaOfPracticeMoreDetails,
        CurrentUserRoles
    } = MembershipFormModel.formField;

    const { control, reset, getValues, setValue, watch } = useFormContext();

    const { titles, genders, countries, disciplines, areasOfPractice } = useAppSelector(state => state.ReferenceData);
    const { personalDetails } = useAppSelector(state => state.personalDetail);

    useEffect(() => {
        if (personalDetails !== undefined && personalDetails !== null
            && titles && titles?.length > 0 && genders && genders?.length > 0
            && countries && countries?.length > 0 && disciplines && disciplines?.length > 0
            && areasOfPractice && areasOfPractice?.length > 0) {
            reset({
                [Title.name]: (personalDetails.titleId === undefined || personalDetails.titleId === null)
                    ? '' : personalDetails.titleId,
                [FirstName.name]:
                    (personalDetails.firstName === undefined || personalDetails.firstName === null)
                        ? '' : personalDetails.firstName,
                [MiddleName.name]:
                    (personalDetails.middleName === undefined || personalDetails.middleName === null)
                        ? '' : personalDetails.middleName,
                [Surname.name]:
                    (personalDetails.lastName === undefined || personalDetails.lastName === null)
                        ? '' : personalDetails.lastName,
                [Preferredname.name]:
                    (personalDetails.prefferedName === undefined || personalDetails.prefferedName === null)
                        ? '' : personalDetails.prefferedName,
                [DateOfBirth.name]: personalDetails.dateOfBirth ?? '',
                [CountryOfNationality.name]: personalDetails.nationalityId ?? '',
                [Gender.name]: personalDetails.genderId ?? '',
                [PreferredEmail1.name]:
                    (personalDetails.preferredEmail === undefined || personalDetails.preferredEmail === null)
                        ? '' : personalDetails.preferredEmail,
                [AlternateEmail2.name]:
                    (personalDetails.email2 === undefined || personalDetails.email2 === null)
                        ? '' : personalDetails.email2,
                [AlternateEmail3.name]:
                    (personalDetails.email3 === undefined || personalDetails.email3 === null)
                        ? '' : personalDetails.email3,
                [PreferredPhoneNumber.name]:
                    (personalDetails.mobile === undefined || personalDetails.mobile === null)
                        ? '' : personalDetails.mobile,
                [AlternatePhoneNumber.name]:
                    (personalDetails.alternatePhoneNumber === undefined || personalDetails.alternatePhoneNumber === null)
                        ? '' : personalDetails.alternatePhoneNumber,
                [LinkedInUrl.name]:
                    (personalDetails.linkedin === undefined || personalDetails.linkedin === null)
                        ? '' : personalDetails.linkedin,
                [Discipline.name]:
                    (personalDetails.disciplineId === undefined || personalDetails.disciplineId === null)
                        ? '' : personalDetails.disciplineId,
                [AreaOfPracticeMoreDetails.name]:
                    (personalDetails.areaOfPracticeId === undefined || personalDetails.areaOfPracticeId === null)
                        ? '' : personalDetails.areaOfPracticeId,
                [CurrentUserRoles.name]: userRoles
            });
        }
        if (!areasOfPractice || areasOfPractice?.length === 0) {
            setValue(AreaOfPracticeMoreDetails.name, MESSAGE_NOT_A_VALUE);
        }
    }, [personalDetails, titles, genders, countries, disciplines, areasOfPractice, reset]);

    useEffect(() => {
        watch((value, { name, type }) => {
            if (name === Discipline.name && type === 'change') {
                if (areasOfPractice?.filter(x => x.disciplineId === getValues(Discipline.name)) &&
                    areasOfPractice?.filter(x => x.disciplineId === getValues(Discipline.name)).length > 0) {
                    setValue(AreaOfPracticeMoreDetails.name, '');
                }
                else {
                    setValue(AreaOfPracticeMoreDetails.name, MESSAGE_NOT_A_VALUE);
                }
            }
        });
    }, [Discipline.name, areasOfPractice, getValues, setValue, watch]);

    function emailPreferencesHelpText() {
        return <span>
            All portal related communications will be sent to your preferred email address.
            <br></br><br></br>
            Email addresses that you are using to log into the portal must be populated
            in one the email fields below e.g. if you are logging in with your google
            account, please ensure your associated email is listed in one of the three
            email addresses below.
            <br></br><br></br>
            If an email associated with your login has been removed from your profile,
            you will need to sign up to the portal again.
        </span >
    }

    const { instance } = useMsal();
    const userRoles = (instance.getActiveAccount()?.idTokenClaims as unknown as IdTokenClaims).extension_portalcrm_userType.split(',');

    return (
        <>
            <Box sx={{ width: '100%' }} component={Paper}>

                <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                    <Grid item xs={12}>
                        <Typography variant='subtitle1'>Please enter your personal details.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <AppAutocomplete control={control} name={Title.name}
                            label={Title.label} items={MapTitlesToAutocompleteItems(titles ?? [])} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput name={FirstName.name} label={FirstName.label} disabled={true} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput name={MiddleName.name} label={MiddleName.label} disabled={personalDetails?.middleName && personalDetails?.middleName !== null ? true : false} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput name={Surname.name} label={Surname.label} disabled={true} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput control={control} name={Preferredname.name} label={Preferredname.label} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppDatePicker
                            control={control}
                            name={DateOfBirth.name}
                            label={DateOfBirth.label}
                            maxDate={minEnrolmentYear()}
                            disabled={personalDetails?.dateOfBirth && personalDetails?.dateOfBirth !== null ? true : false} />
                    </Grid>

                    <Grid item xs={12}>

                        <AppAutocomplete
                            control={control} name={CountryOfNationality.name}
                            label={CountryOfNationality.label}
                            items={MapCountriesToAutocompleteItems(countries ?? [])}
                            disabled={personalDetails?.nationalityId && personalDetails?.nationalityId !== null ? true : false}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AppAutocomplete control={control}
                            name={Gender.name} label={Gender.label}
                            items={MapGendersToAutocompleteItems(genders ?? [])}
                        />
                    </Grid>

                    <Box sx={{
                        width: '92%', backgroundColor: '#e7e7e7', padding: '25px',
                        paddingRight: '0px', marginTop: '10px'
                    }}>
                        <Grid container rowSpacing={4}>

                            <Grid item xs={7} md={3} sm={5} lg={3}>
                                <Typography variant='h6'>Email Preferences</Typography>
                            </Grid>

                            <Grid item xs={1} md={1} sm={1} lg={1}></Grid>

                            <Grid item xs={4} md={8} sm={6} lg={8}></Grid>

                            <Grid item xs={12}>
                                <Typography variant='body1'>{emailPreferencesHelpText()}</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <AppTextInput control={control} name={PreferredEmail1.name}
                                    label={PreferredEmail1.label}
                                    information_text={PreferredEmail1.informationText} />
                            </Grid>

                            <Grid item xs={12}>
                                <AppTextInput control={control} name={AlternateEmail2.name}
                                    label={AlternateEmail2.label} />
                            </Grid>

                            <Grid item xs={12}>
                                <AppTextInput control={control} name={AlternateEmail3.name}
                                    label={AlternateEmail3.label} />
                            </Grid>
                        </Grid>
                    </Box>

                    <Grid item xs={12}>
                        <AppTextInput control={control} name={PreferredPhoneNumber.name}
                            label={PreferredPhoneNumber.label} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput control={control} name={AlternatePhoneNumber.name} label={AlternatePhoneNumber.label} />
                    </Grid>

                    {(alumniFilter(userRoles) || famFilter(userRoles)) &&
                        <>
                            <Grid item xs={12}>
                                <AppTextInput control={control} name={LinkedInUrl.name}
                                    label={LinkedInUrl.label} />
                            </Grid>

                            <Grid item xs={12}>
                                <AppAutocomplete control={control} name={Discipline.name} label={Discipline.label}
                                    items={MapDisciplinesToAutocompleteItems(disciplines ?? [])}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <AppAutocomplete control={control} name={AreaOfPracticeMoreDetails.name}
                                    label={AreaOfPracticeMoreDetails.label}
                                    items={MapAreaOfPracticesToAutocompleteItems(areasOfPractice?.filter(x => x.disciplineId === getValues(Discipline.name)) ?? [])}
                                />
                            </Grid>
                        </>
                    }

                    <Grid item xs={12}></Grid>

                </Grid>
            </Box>
        </>
    );
}