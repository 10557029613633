import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { PersonalDetailsDto } from "../models/person/personalDetailsDto";
import { FileDto } from "../models/membership/fileDto";
import { FileDetailsParam } from "../../features/myDocuments/models/fileDetailsParam";

interface PersonalDetailsState {
    personalDetails: PersonalDetailsDto | null;
    files: FileDto[] | null;
    downloadStatus: ApiRequestStatus;

    personalDetailsStatus: ApiRequestStatus;
    updatePersonalDetailsStatus: ApiRequestStatus;
    getDocumentsStatus: ApiRequestStatus;
}

const initialState: PersonalDetailsState = {
    personalDetails: null,
    files: null,

    personalDetailsStatus: ApiRequestStatus.Idle,
    updatePersonalDetailsStatus: ApiRequestStatus.Idle,
    getDocumentsStatus: ApiRequestStatus.Idle,
    downloadStatus: ApiRequestStatus.Idle
}

export const getPersonalDetailsAsync = createAsyncThunk<PersonalDetailsDto, string>(
    'prson/getPersonalDetailsAsync',
    async (personId, thunkAPI) => {
        try {
            return agent.PersonalDetails.getPersonalDetailsAsync(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updatePersonalDetailsStateAsync = createAsyncThunk<void, PersonalDetailsDto>(
    'prson/updatePersonalDetailsStateAsync',
    async (personalDetails, thunkAPI) => {
        try {
            return agent.PersonalDetails.updatePersonalDetailsAsync(personalDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getFilesAsync = createAsyncThunk<FileDto[]>(
    'prson/getFilesAsync',
    async (_, thunkAPI) => {
        try {
            return agent.PersonalDetails.getFilesAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const downloadFileAsync = createAsyncThunk<FormData, FileDetailsParam>(
    'prson/downloadFileAsync',
    async (downloadFileParam, thunkAPI) => {
        try {
            return agent.PersonalDetails.getFileAsync(downloadFileParam.personId, downloadFileParam.fileName);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const personSlice = createSlice({
    name: 'personSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getPersonalDetailsAsync.pending, (state) => {
            state.personalDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getPersonalDetailsAsync.fulfilled, (state, action) => {
            state.personalDetailsStatus = ApiRequestStatus.Fulfilled;
            state.personalDetails = action.payload;
        });
        builder.addCase(getPersonalDetailsAsync.rejected, (state) => {
            state.personalDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(updatePersonalDetailsStateAsync.pending, (state) => {
            state.updatePersonalDetailsStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(updatePersonalDetailsStateAsync.fulfilled, (state) => {
            state.updatePersonalDetailsStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(updatePersonalDetailsStateAsync.rejected, (state) => {
            state.updatePersonalDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getFilesAsync.pending, (state) => {
            state.getDocumentsStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(getFilesAsync.fulfilled, (state, action) => {
            state.files = action.payload;
            state.getDocumentsStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(getFilesAsync.rejected, (state) => {
            state.getDocumentsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(downloadFileAsync.pending, (state) => {
            state.downloadStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(downloadFileAsync.fulfilled, (state, action) => {
            state.downloadStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(downloadFileAsync.rejected, (state) => {
            state.downloadStatus = ApiRequestStatus.Rejected;
        });
    })
})
