import { Box, Grid, Typography } from "@mui/material";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import AppLabelField from "../../../../../app/components/AppLabelField";
import { useAppSelector } from "../../../../../app/store/configureStore";

export function ViewReviewer() {
    const { Title, FirstName, Surname } = pcsPlanFormModel.formField;

    const { titles } = useAppSelector(state => state.ReferenceData);
    const { reviewerDetails } = useAppSelector(state => state.pcsPdp);

    return <>
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}>
                    <Typography variant='h6'>Reviewer details</Typography>
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}>
                    <AppLabelField value={titles?.find(x => x.id === reviewerDetails?.titleId)?.value} label={Title.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppLabelField value={reviewerDetails?.firstnames} label={FirstName.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppLabelField value={reviewerDetails?.surname} label={Surname.label} />
                </Grid>

            </Grid>
        </Box >
    </>
}