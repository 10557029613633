import { AppPathEnum } from "../enums/appPathEnum";
import { CountryNameEnum } from "../enums/countryNameEnum";
import { CurrencyCodeEnum } from "../enums/currencyCodeEnum";
import { CurrencyCodeIconEnum } from "../enums/currencyCodeIconEnum";
import { Environment } from "../enums/environment";
import { UserRole } from "../enums/userRole";
import { AutocompleteItem } from "../models/common/autocompleteItem";
import { QualificationYear } from "../models/common/qualificationYear";
import { RadioButtonValue } from "../models/radioButtonValue";
import { MIN_AGE_TO_ENROLL } from "./constant";

export function GetColorCode(colorName: string) {
  switch (colorName.toUpperCase()) {
    case "RED":
      return 'primary.main';
    case "AMBER":
      return "#e46c0a";
    case "GREEN":
      return "#00af66";
    case "WHITE":
      return "#FFFFFF";
    default:
      return "#FFFFFF";
  }
}

export const GetSummaryColorCode = (colorName: string) => {
  switch (colorName.toUpperCase()) {
      case "RED":
          return '#d64b5f';
      case "AMBER":
          return "#e46c0a";
      case "GREEN":
          return "#00af66";
      case "WHITE":
          return "#FFFFFF";
      default:
          return "#FFFFFF";
  }
}

export function getCookie(key: string) {
  const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export function currencyFormat(amount: number) {
  return '$' + (amount / 100).toFixed(2);
}

export const formatDropdownValues = (items: any[]) => {

  if (items !== null && items && items[0]) {
    const result = Object.values(items);
    const data: any[] = [];
    result.forEach((item) => {
      data.push({ value: item.id, label: item.value });
    });
    return data;
  }
  return null;
}

export function yearList(initialYear: number) {
  let startYear = initialYear;
  let lastYear = new Date().getFullYear() - 6, years: QualificationYear[] = [];
  while (startYear >= lastYear) {
    const qualificationYear = {
      id: startYear.toString(),
      value: startYear.toString(),
    };
    years.push(qualificationYear);
    startYear--;
  }
  return years;
}

export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export const weekDayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday",
  "Friday", "Saturday"
];

export function getValueFromId(data: any, id: string) {
  return data?.filter((x: any) => x.id === id)[0]?.value;
}

export function getValueFromIdList(data: any[], ids: any[]) {
  let selectedValues: any[] = [];
  data?.forEach((item) => {
    ids?.forEach((id) => {
      if (item.id === id) {
        selectedValues.push(item.value);
      }
    })
  });
  return selectedValues;
}

export function formatDate(date: Date) {
  var month = '' + (date.getMonth() + 1),
    day = '' + date.getDate(),
    year = date.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [day, month, year].join('/');
}

export const formatDateWithTime = (date: Date) => {

  date = new Date(date.setMinutes(date.getMinutes() - date.getTimezoneOffset()));

  let month = '' + (date.getMonth() + 1),
    day = '' + date.getDate(),
    year = date.getFullYear(),
    hour = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours(),
    minute = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes(),
    second = date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  let formatedDate = [day, month, year].join('/');

  let formatTime = [hour, minute, second].join(':');

  return [formatedDate, formatTime].join(' ');
}

export function formatDateWithMonthName(date: Date) {
  var month = '' + monthNames[(date.getMonth())],
    day = '' + date.getDate(),
    year = date.getFullYear();

  if (day.length < 2)
    day = '0' + day;

  return [day, month, year].join(' ');
}

export function formatDateMonthName(date: Date) {
  var month = '' + monthNames[(date.getMonth())],
    year = date.getFullYear();

  return [month, year].join(' ');
}

export function practiceOfMedicineOptions() {
  var options: AutocompleteItem[] = [];
  options.push({ label: "Engaged in Practice of Medicine", id: "1" });
  options.push({ label: "Not Engaged in Practice of Medicine", id: "0" });
  return options;
}

export function selectAllWrapperToAutocompleteItems(autocompleteValues: AutocompleteItem[]) {
  autocompleteValues = [{ id: "0", label: "Select All" }, ...autocompleteValues];
  return autocompleteValues;
}

export const formatBytes = (bytes: number) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];

  let i = 0;

  for (i; bytes >= 1024 && i < 4; i++) {
    bytes /= 1024;
  }

  return `${bytes.toFixed(2)} ${units[i]}`;
};

export const yesNoArray: RadioButtonValue[] = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' }
]

export const countryCodes = ['AC', 'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR', 'BS', 'BT', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'EH', 'ER', 'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GT', 'GU', 'GW', 'GY', 'HK', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TA', 'TC', 'TD', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'XK', 'YE', 'YT', 'ZA', 'ZM', 'ZW'];

export const minEnrolmentYear = () => {
  let expectedDate = new Date(new Date().setFullYear(new Date().getFullYear() - MIN_AGE_TO_ENROLL));
  return new Date(expectedDate.setDate(expectedDate.getDate() - 1));
}

export const getFileExtension = (fileName: string) => {
  return fileName.split('.').pop();
}

export const convertToPrecesion = (numberToBeFormatted: string | number | undefined) => {
  if (numberToBeFormatted === undefined) { return "0.00"; }

  let formattedNumber: string = '';
  var numberAfterSplit: string[] = numberToBeFormatted?.toString().split('.');

  if (numberAfterSplit.length >= 2) {
    if (numberAfterSplit[1].length === 2) {
      return numberToBeFormatted;
    }
    else if (numberAfterSplit[1].length === 1) {
      return numberToBeFormatted + '0';
    }
    else if (numberAfterSplit[1].length === 0) {
      return numberToBeFormatted + '00';
    }
  }
  else if (numberAfterSplit.length === 1) {
    return numberToBeFormatted + '.00';
  }
  return formattedNumber;
}

export const addDays = (date: Date, days: number): Date => {
  const dateWithHours = new Date(date.setDate(date.getDate() + days));
  const dateWithoutHours = new Date(dateWithHours.setHours(0, 0, 0, 0));
  return dateWithoutHours;
}

export const alumniFilter = (userRoles: string[]) => {
  return userRoles.includes(UserRole.ALUMNI)
    || userRoles.includes(UserRole.ALUMNI_BAH);
}

export const famFilter = (userRoles: string[]) => {
  return userRoles.includes(UserRole.FAM_MEMBER) ||
    userRoles.includes(UserRole.FAM_FELLOW);
}

export const affiliateAssociateFilter = (userRoles: string[]) => {
  return userRoles.includes(UserRole.FAM_AFFILIATE) ||
    userRoles.includes(UserRole.FAM_ASSOCIATE);
}

export const pcsFilter = (userRoles: string[]) => {
  return userRoles.includes(UserRole.PCS_EM)
    || userRoles.includes(UserRole.PCS_OPHTH)
    || userRoles.includes(UserRole.PCS_RADIOLOGY)
    || userRoles.includes(UserRole.PCS_SEM)
    || userRoles.includes(UserRole.PCS_SURGERY);
}

export const isValidUrl = (url: string) => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(url);
}

export const updateProfileWithRefLink = () => {
  return `${AppPathEnum.UPDATE_PROFILE}/?ref=${encodeURIComponent(new URLSearchParams(window.location.search).get("ref") ?? '')}`;
}

export const isProductionMode = (): boolean => {
  if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
    return true;
  }
  return false;
}

export const getCurrencyByRegion = (region: string) => {
  if (region.toLowerCase() === CountryNameEnum.UK.toLowerCase()) {
    return CurrencyCodeEnum.GBP;
  }
  else if (region.toLowerCase() === CountryNameEnum.USA.toLowerCase()) {
    return CurrencyCodeEnum.USD;
  }
  else {
    return CurrencyCodeEnum.EUR;
  }
}

export const getCurrencyIconByCode = (code: string | undefined) => {
  if (code === CurrencyCodeEnum.EUR) {
    return CurrencyCodeIconEnum.EUR;
  }
  else if (code === CurrencyCodeEnum.GBP) {
    return CurrencyCodeIconEnum.UK;
  }
  else if (code === CurrencyCodeEnum.USD) {
    return CurrencyCodeIconEnum.USA;
  }
}
