import { Box, Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import AppTextInput from "../../../app/components/AppTextInput";
import { useReferenceData } from "../../../app/customHooks/useReferenceData";
import { ApiRequestStatus } from "../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useAppSelector } from "../../../app/store/configureStore";
import { MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";
import { useGetRegistrationDetails } from "../../../app/customHooks/useGetRegistrationDetails";
import { SchemEnum } from "../../../app/enums/schemEnum";
import { practiceOfMedicineOptions } from "../../../app/utils/util";
import registrationFormModel from "../../../app/models/pcsEnrolment/registrationFormModel";
import { useState, useEffect } from "react";
import { MapRegistrationTypesToAutocompleteItems, MapSchemesToAutocompleteItems, MapSpecialitiesToAutocompleteItems } from "../../../app/utils/convertStaticDataToDropdownItems";
import { AutocompleteItem } from "../../../app/models/common/autocompleteItem";
import AppAutocomplete from "../../../app/components/AppAutocomplete";

export function PCSDetails() {
    const {
        registrationDetailsFormField: {
            IMCNumber,
            RegistrationType,
            Scheme,
            EngagedInPracticeOfMedicine,
            Specialty,
            SubSpecialty,
            UserConfirmation
        }
    } = registrationFormModel;

    const methods = useFormContext();
    const { control, watch, reset, getValues, formState: { isDirty } } = methods;

    const { staticDataStateStatus, specialities, schemes, registrationTypes } = useAppSelector(state => state.ReferenceData);
    const { registrationDetails, registerStateStatus, saveRegistrationDetailsStatus } = useAppSelector(state => state.pcs);

    const [filteredSpecialities, setFilteredSpecialities] = useState<AutocompleteItem[]>([]);

    useReferenceData();
    useGetRegistrationDetails();

    useEffect(() => {
        if (registrationDetails && registrationDetails !== null && registrationDetails.schemeId) {
            filteredSpecialitiesByScheme(registrationDetails.schemeId);
        }
    }, [registrationDetails]);

    useEffect(() => {
        if (registrationDetails && registrationDetails !== null
            && schemes && schemes?.length > 0 && specialities && specialities?.length > 0
            && registrationTypes && registrationTypes?.length > 0
            && filteredSpecialities && filteredSpecialities?.length > 0
            && isDirty === false) {

            reset({
                [IMCNumber.name]: registrationDetails?.imcNumber === null ? '' : registrationDetails?.imcNumber,
                [RegistrationType.name]: registrationDetails?.registrationTypeId === null ? '' : registrationDetails?.registrationTypeId,
                [Scheme.name]: registrationDetails?.schemeId === null ? '' : registrationDetails?.schemeId,
                [EngagedInPracticeOfMedicine.name]:
                    registrationDetails?.engagedInPracticeOfMedicine === true ? '1' : '0',
                [Specialty.name]: registrationDetails?.specialtyId === null ? '' : registrationDetails?.specialtyId,
                [SubSpecialty.name]: registrationDetails?.subSpeciality === null ? '' : registrationDetails?.subSpeciality,
                [UserConfirmation.name]: []
            });
        }
    }, [registrationDetails, schemes, specialities, filteredSpecialities, registrationTypes, reset]);

    useEffect(() => {
        watch((_, { name, type }) => {
            if (name === Scheme.name && type === 'change') {
                filteredSpecialitiesByScheme(getValues(Scheme.name));
            }
        });
    }, [Scheme.name, watch]);

    const filteredSpecialitiesByScheme = (schemeId: string) => {
        let specialitiesByScheme = specialities?.filter(x => x.schemeId === schemeId) ?? [];
        let specialtyList: AutocompleteItem[] = MapSpecialitiesToAutocompleteItems(specialitiesByScheme);
        setFilteredSpecialities(specialtyList);
    }

    if (staticDataStateStatus === ApiRequestStatus.Pending
        || registerStateStatus === ApiRequestStatus.Pending
        || saveRegistrationDetailsStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>

                <Typography variant="h4" gutterBottom sx={{ mb: 4 }}></Typography>

                <Grid container rowSpacing={4}>
                    <Grid item xs={12}>
                        <Typography variant='subtitle1'>Please enter your PCS details.</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <AppTextInput control={control}
                            name={IMCNumber.name}
                            label={IMCNumber.label}
                            disabled={true} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppAutocomplete control={control}
                            name={RegistrationType.name}
                            label={RegistrationType.label}
                            items={MapRegistrationTypesToAutocompleteItems(registrationTypes ?? [])}
                            disabled={true} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppAutocomplete control={control}
                            name={Scheme.name}
                            label={Scheme.label}
                            items={MapSchemesToAutocompleteItems(schemes ?? [])}
                            disabled={true} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppAutocomplete control={control}
                            name={EngagedInPracticeOfMedicine.name}
                            label={EngagedInPracticeOfMedicine.label}
                            items={practiceOfMedicineOptions()}
                            disabled={true} />
                    </Grid>

                    <Grid item xs={12}>
                        <AppAutocomplete control={control}
                            name={Specialty.name}
                            label={Specialty.label}
                            items={filteredSpecialities ? filteredSpecialities : []} />
                    </Grid>

                    {schemes !== null && schemes.find(x => x.id === getValues(Scheme.name))?.code === SchemEnum.SURGERY &&
                        <>
                            <Grid item xs={12}>
                                <AppTextInput control={control}
                                    name={SubSpecialty.name}
                                    label={SubSpecialty.label} />
                            </Grid>
                        </>}

                </Grid>
            </Box>
        </>
    );
}