export enum PdpStatusEnum {
    Draft = "DT",
    NotStarted = "NS",
    ReviewRequestCancelled = "RRC",
    ReviewRequestDeclined = "RRD",
    ReviewRequestInvalid = "RRI",
    ReviewRequestPending = "RP",
    Reviewed = "RD",
    ReviewerValidation = "RV",
    Submitted = "SD",
    UnderReview = "UR"
}