import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { getValueFromId } from "../../../../app/utils/util";
import { CPDRecordStatusChange } from "../../models/CPDRecordStatusChange";
import { getCpdStatusHistoryAsync } from "../../../../app/slices/creditsSlice";
import { useReferenceData } from "../../../../app/customHooks/useReferenceData";
import { MESSAGE_PROCESSING_DETAILS } from "../../../../app/utils/constant";

export function RecordStatusHistory() {
    const { creditRecordId } = useParams<{ creditRecordId: string }>();

    const dispatch = useAppDispatch();
    const { cpdRecordStatuses, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { cpdStatusHistory, getCpdHistoryStatus } = useAppSelector(state => state.credits);

    const [statusHistoryRecords, setStatusHistoryRecords] = useState<CPDRecordStatusChange[]>();

    useEffect(() => {
        if (cpdStatusHistory && cpdStatusHistory?.length > 0) {
            let statusHistory: CPDRecordStatusChange[] = [];
            const cpdStatusHistoryAscending = cpdStatusHistory.slice().sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
            let currentStatus = "";
            let currentNote = "";
            cpdStatusHistoryAscending.map((item) => {
                if (item.oldNote !== "" && item.note === "") {
                    currentNote = "";
                }
                else if (item.oldNote === "" && item.note !== "") {
                    currentNote = item.note;
                }
                else if (item.oldNote !== "" && item.note !== "") {
                    currentNote = item.note;
                }

                if (item.oldCPDRecordStatusId !== "" && item.cpdRecordStatusId === "") {
                    currentStatus = "";
                }
                else if (item.oldCPDRecordStatusId === "" && item.cpdRecordStatusId !== "") {
                    currentStatus = item.cpdRecordStatusId;
                }
                else if (item.oldCPDRecordStatusId !== "" && item.cpdRecordStatusId !== "") {
                    currentStatus = item.cpdRecordStatusId;
                }

                statusHistory.push({
                    creditRecordId: item.creditRecordId,
                    cpdRecordStatusId: currentStatus,
                    date: item.date,
                    note: currentNote,
                    oldCPDRecordStatusId: item.oldCPDRecordStatusId,
                    oldNote: item.oldNote
                });
            });
            setStatusHistoryRecords(statusHistory.slice().sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()));
        }
    }, [cpdStatusHistory]);

    useEffect(() => {
        dispatch(getCpdStatusHistoryAsync(creditRecordId));
    }, [creditRecordId, dispatch]);

    useReferenceData();

    return <>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell><b>Date</b></TableCell>
                        <TableCell align="left"><b>Status</b></TableCell>
                        <TableCell align="left"><b>Note</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {statusHistoryRecords?.map((row: CPDRecordStatusChange, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>
                                {row.date &&
                                    (new Date(row.date).getDate() + '/' +
                                        (new Date(row.date).getMonth() + 1) + '/' +
                                        new Date(row.date).getFullYear())
                                }
                            </TableCell>
                            <TableCell align="left">
                                {getValueFromId(cpdRecordStatuses, row.cpdRecordStatusId)}
                            </TableCell>
                            <TableCell align="left">{row.note}</TableCell>
                        </TableRow>
                    ))}
                    {cpdStatusHistory?.length === 0 &&
                        <TableRow>
                            <TableCell colSpan={9} align="center">
                                No data found
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>
}
