import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { useParams } from "react-router-dom";
import { PdpStatusEnum } from "../../models/pdpStatusEnum";
import ViewPlannedActivities from "../planDetails/viewPlan/VewPlannedActivities";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../../../app/utils/constant";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import { getPcsEnrolmentYearsAsync } from "../../../../app/slices/pcsEnrolmentSlice";
import { getPcsCurrentYearAsync } from "../../../../app/slices/pcsSlice";
import { getReferenceDataAsync } from "../../../../app/slices/referenceDataSlice";
import { ActivityDetails } from "../planDetails/ActivityDetails";
import { UpdatePlannedActivities } from "../planDetails/updatePlan/UpdatePlannedActivities";

export default function PlanDetails() {
    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const { pcsPdpStatuses, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { enrolmentYears, enrolmentYearsStatus } = useAppSelector(state => state.pcsEnrolment);
    const { currentYear, currentYearStatus } = useAppSelector(state => state.pcs);

    const methods = useForm();
    const [canUpdatePlannedActivities, setCanUpdatePlannedActivities] = useState<boolean>();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getReferenceDataAsync());
        dispatch(getPcsEnrolmentYearsAsync());
        dispatch(getPcsCurrentYearAsync());
    }, []);

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0 && pcsPdpStatuses && currentYear) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            const pcsPdpStatus = pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId);

            setCanUpdatePlannedActivities((pcsPdpStatus?.code === PdpStatusEnum.Draft || pcsPdpStatus?.code === PdpStatusEnum.NotStarted)
                && enrolmentYear?.year === currentYear);
        }
    }, [enrolmentYears, pcsPdpStatuses, currentYear]);

    if (enrolmentYearsStatus === ApiRequestStatus.Pending
        || staticDataStateStatus === ApiRequestStatus.Pending
        || currentYearStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <FormProvider {...methods}>
            {canUpdatePlannedActivities === true && <>
                <ActivityDetails />
                <UpdatePlannedActivities canUpdatePlannedActivity={true} />
            </>}
            {canUpdatePlannedActivities === false && <ViewPlannedActivities />}
        </FormProvider >
    </>
}
