import { Box, Button, Grid, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import registrationFormModel from '../../app/models/pcsEnrolment/registrationFormModel';
import AppTextInput from '../../app/components/AppTextInput';
import { useAppSelector } from '../../app/store/configureStore';
import AppAutocomplete from '../../app/components/AppAutocomplete';
import { MapCountriesToAutocompleteItems, MapInstitutionsToAutocompleeItems }
  from '../../app/utils/convertStaticDataToAutocompleteItems';
import AppTextareaInput from '../../app/components/AppTextareaInput';
import { AutocompleteItem } from '../../app/models/common/autocompleteItem';
import { Institution } from '../../app/models/staticData/institution';

interface Prop {
  reenrol: boolean;
}

export default function EducationalDetails({ reenrol }: Prop) {

  const methods = useFormContext();
  const { control, getValues, setValue, reset, watch, formState: { isDirty } } = methods;

  const {
    personalDetailsFormField: {
      DOB
    },
    educationalDetailsFormField: {
      QualificationCountry,
      PrimaryMedicalDegreeCollege,
      OtherPrimaryMedicalDegreeCollege,
      QualificationYear
    } } = registrationFormModel;

  const { countries, educationInstitutions } = useAppSelector(state => state.ReferenceData);
  const { personalDetails } = useAppSelector(state => state.personalDetail);
  const { registrationDetails } = useAppSelector(state => state.pcs);

  const [filteredInstitutions, setFilteredInstitutions] = useState<Institution[]>();
  const [showOtherUniversityOrCollege, setShowOtherUniversityOrCollege] = useState<boolean>(false);

  useEffect(() => {
    if (getValues([QualificationCountry.name]).toString() !== "" &&
      educationInstitutions && educationInstitutions.length > 0) {
      setFilteredInstitutions(educationInstitutions.filter(x => x.countryId === getValues([QualificationCountry.name]).toString()));
    }
  }, [educationInstitutions, getValues]);

  useEffect(() => {
    watch((_, { name, type }) => {
      if (type === 'change') {
        if (educationInstitutions && name === QualificationCountry.name) {
          if (getValues(QualificationCountry.name) === '') {
            setFilteredInstitutions(educationInstitutions.filter(x => x.countryId === getValues([QualificationCountry.name]).toString()));
          }
        }
        if (name === QualificationCountry.name ||
          (name === PrimaryMedicalDegreeCollege.name && getValues(PrimaryMedicalDegreeCollege.name) === undefined)) {
          setValue(PrimaryMedicalDegreeCollege.name, '');
        }

        if (name === OtherPrimaryMedicalDegreeCollege.name) {
          if (getValues(OtherPrimaryMedicalDegreeCollege.name) === '') {
            setShowOtherUniversityOrCollege(false);
          } else {
            setShowOtherUniversityOrCollege(true);
          }
        }
      }
    });
  }, [PrimaryMedicalDegreeCollege.name, QualificationCountry.name, educationInstitutions,
    filteredInstitutions, watch, getValues, setValue]);

  const getQualificationYears = (startYear: number) => {
    let lastYear: number = new Date().getFullYear();
    let years: AutocompleteItem[] = [];

    while (startYear <= lastYear) {
      const qualificationYear = {
        id: startYear.toString(),
        label: startYear.toString(),
      };
      years.push(qualificationYear);
      startYear++;
    }
    return years;
  }

  const [qualificationYears,] = useState<AutocompleteItem[] | null>(
    getQualificationYears((new Date(personalDetails?.dateOfBirth!).getFullYear() + 16)));

  useEffect(() => {
    if (personalDetails !== undefined && personalDetails !== null
      && registrationDetails !== undefined && registrationDetails !== null
      && isDirty === false) {
      reset({
        [QualificationCountry.name]: registrationDetails?.qualificationCountryId ?? '',
        [PrimaryMedicalDegreeCollege.name]: registrationDetails?.qualificationInstitutionId ?? '',
        [OtherPrimaryMedicalDegreeCollege.name]: registrationDetails?.qualificationInstitutionOther ?? '',
        [QualificationYear.name]: registrationDetails?.qualificationYear?.toString() ?? '',
        [DOB.name]: personalDetails.dateOfBirth ?? ''
      });

      if (registrationDetails?.qualificationInstitutionOther !== "" &&
        registrationDetails?.qualificationInstitutionOther !== undefined &&
        registrationDetails?.qualificationInstitutionOther !== null) {
        setShowOtherUniversityOrCollege(true);
      }
      else {
        setShowOtherUniversityOrCollege(false);
      }
    }
  }, [personalDetails, registrationDetails, showOtherUniversityOrCollege, reset, isDirty]);

  return (
    <Box sx={{ minWidth: "100%" }} component={Paper}>
      <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

        <Grid item xs={12}>
          <AppAutocomplete name={QualificationCountry.name} label={QualificationCountry.label}
            items={MapCountriesToAutocompleteItems(countries ?? [])}
            disabled={reenrol === true && registrationDetails?.qualificationCountryId !== null}
            information_text={reenrol === false ? QualificationCountry.information_text : ''} />
        </Grid>

        {getValues([OtherPrimaryMedicalDegreeCollege.name]).toString() === '' &&
          <Grid item xs={12}>
            <AppAutocomplete name={PrimaryMedicalDegreeCollege.name} label={PrimaryMedicalDegreeCollege.label}
              items={MapInstitutionsToAutocompleeItems(educationInstitutions ?? [],
                getValues([QualificationCountry.name]).toString())}
              disabled={reenrol === true && registrationDetails?.qualificationInstitutionId !== null}
              information_text={reenrol === false ? PrimaryMedicalDegreeCollege.information_text : ''} />

            {(reenrol === false && (getValues(PrimaryMedicalDegreeCollege.name) === undefined ||
              getValues(PrimaryMedicalDegreeCollege.name) === "")) && <Button variant="text"
                style={{ fontSize: '12px', color: 'blue', textDecoration: 'underline' }}
                onClick={() => setShowOtherUniversityOrCollege(true)}>
                I cannot find my University or College</Button>}
          </Grid>
        }

        {showOtherUniversityOrCollege === true && <Grid item xs={12}>
          <AppTextInput control={control} name={OtherPrimaryMedicalDegreeCollege.name}
            label={OtherPrimaryMedicalDegreeCollege.label}
            disabled={reenrol === true && registrationDetails?.qualificationInstitutionOther !== null} />
        </Grid>}

        <Grid item xs={12}>
          <AppAutocomplete control={control} name={QualificationYear.name} label={QualificationYear.label}
            items={qualificationYears ?? []} disabled={reenrol === true && registrationDetails?.qualificationYear !== null}
            information_text={reenrol === false ? QualificationYear.information_text : ''} />
        </Grid>

        <Grid item xs={12}></Grid>

      </Grid>
    </Box>
  );
}
