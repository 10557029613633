import * as yup from 'yup';
import membershipFormModel from '../../../app/models/membership/membershipFormModel';
import { PersonalDetailsValidationSchema, PostalAddressValidationSchema } from '../../../app/models/membership/validationSchemas';
import { MESSAGE_NOT_A_VALUE } from '../../../app/utils/constant';
import { UserRole } from '../../../app/enums/userRole';
import registrationFormModel from '../../../app/models/pcsEnrolment/registrationFormModel';
import { UpdateProfileStepsEnum } from './updateProfileStepsEnum';
import { WorkDetailsValidationSchema } from '../../../app/models/pcsEnrolment/registrationValidationSchema';

const {
  formField: {
    SurgeonsScopeMagazine,
    TheSurgeonJournal,
    AlumniMagazinePreference,
    LocationCountry,
    CountrySubdivision,
    CurrentUserRoles
  }
} = membershipFormModel;

const {
  registrationDetailsFormField: {
    IMCNumber,
    RegistrationType,
    Scheme,
    EngagedInPracticeOfMedicine,
    Specialty
  }
} = registrationFormModel;

const LocationAndPreferencesValidationSchema = yup.object().shape({
  [LocationCountry.name]: yup.string().required(`${LocationCountry.requiredErrorMsg}`),
  [CountrySubdivision.name]: yup.string().test(`${CountrySubdivision.name}`,
    `${CountrySubdivision.requiredErrorMsg}`,
    function (countrySubdivision) {
      if (countrySubdivision === MESSAGE_NOT_A_VALUE) {
        return true;
      }
      if (countrySubdivision === "") {
        return false;
      }
      return true;
    }),
  [AlumniMagazinePreference.name]: yup.number().when([CurrentUserRoles.name], ([currentUserRoles]: string[]) => {
    if (currentUserRoles.includes(UserRole.ALUMNI) || currentUserRoles.includes(UserRole.ALUMNI_BAH)) {
      return yup.string().required(`${AlumniMagazinePreference.requiredErrorMesssag}`);
    }
    return yup.string().notRequired();
  }),
  [SurgeonsScopeMagazine.name]: yup.number().when([CurrentUserRoles.name], ([currentUserRoles]: string[]) => {
    if (currentUserRoles.includes(UserRole.FAM_FELLOW) || currentUserRoles.includes(UserRole.FAM_MEMBER)) {
      return yup.string().required(`${SurgeonsScopeMagazine.requiredErrorMesssag}`);
    }
    return yup.string().notRequired();
  }),
  [TheSurgeonJournal.name]: yup.number().when([CurrentUserRoles.name], ([currentUserRoles]: string[]) => {
    if (currentUserRoles.includes(UserRole.FAM_FELLOW) || currentUserRoles.includes(UserRole.FAM_MEMBER)) {
      return yup.string().required(`${TheSurgeonJournal.requiredErrorMesssag}`);
    }
    return yup.string().notRequired();
  })
});

const PcsDetailsValidationSchema = yup.object().shape({
  [IMCNumber.name]: yup.number().required(`${IMCNumber.requiredErrorMsg}`).typeError(`${IMCNumber.typeError}`),
  [RegistrationType.name]: yup.string().required(`${RegistrationType.requiredErrorMsg}`),
  [Scheme.name]: yup.string().required(`${Scheme.requiredErrorMsg}`),
  [EngagedInPracticeOfMedicine.name]: yup.string().required(`${EngagedInPracticeOfMedicine.requiredErrorMsg}`),
  [Specialty.name]: yup.string().required(`${Specialty.requiredErrorMsg}`)
});

export const updateProfileValidationSchemas = [
  { index: UpdateProfileStepsEnum.PersonalDetails, schema: PersonalDetailsValidationSchema },
  { index: UpdateProfileStepsEnum.PostalAddress, schema: PostalAddressValidationSchema },
  { index: UpdateProfileStepsEnum.LocationAndPreferences, schema: LocationAndPreferencesValidationSchema },
  { index: UpdateProfileStepsEnum.PcsDetails, schema: PcsDetailsValidationSchema },
  { index: UpdateProfileStepsEnum.WorkDetails, schema: WorkDetailsValidationSchema },
];
