import { Box, Button, Grid } from "@mui/material";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PcsPdpStatus } from "../../../../../app/models/staticData/pcsPdpStatus";
import { useAppDispatch, useAppSelector } from "../../../../../app/store/configureStore";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { searchReviewerByPersonIdAsync, cancelPlanAsync } from "../../../../../app/slices/pcsPdpSlice";
import { useFormContext } from "react-hook-form";
import { PdpStatusEnum } from "../../../models/pdpStatusEnum";
import { ViewReviewer } from "./ViewReviewer";
import { toast } from "react-toastify";
import { API_REQUEST_FULLFILLED, MESSAGE_PLAN_CANCELED, MESSAGE_PROCESSING_DETAILS } from "../../../../../app/utils/constant";
import { CancelPlanParameters } from "../../../models/cancelPlanParameters";
import { ActivityDetails } from "../ActivityDetails";
import { ViewFeedback } from "./ViewFeedback";
import { ApiRequestStatus } from "../../../../../app/enums/apiRequestStatus";
import { PcsEnrolmentYear } from "../../../models/pcsEnrolmentYear";
import { getPcsEnrolmentYearsAsync } from "../../../../../app/slices/pcsEnrolmentSlice";
import { AppPathEnum } from "../../../../../app/enums/appPathEnum";
import { UpdatePlannedActivities } from "../updatePlan/UpdatePlannedActivities";
import { getCreditSummaryAsync } from "../../../../../app/slices/pcsSlice";

export default function ViewPlannedActivities() {
    const methods = useFormContext();
    const { reset } = methods;

    const { External, WorkBasedLearning, PracticeReview } = pcsPlanFormModel.formField;

    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const [enrolmentYear, setEnrolmentYear] = useState<PcsEnrolmentYear | undefined>();
    const [isCurrentYearPlan, setIsCurrentYearPlan] = useState<boolean>();
    const [canUpdatePlannedActivities, setCanUpdatePlannedActivities] = useState<boolean>();
    const [canViewReviewerDetails, setCanViewReviewerDetails] = useState<boolean>();
    const [canCancelReviewRequest, setCanCancelReviewRequest] = useState<boolean>();
    const [pcsPdpStatus, setPcsPdpStatus] = useState<PcsPdpStatus>();

    const dispatch = useAppDispatch();

    const { pcsPdpStatuses, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { reviewerDetails, planStateStatus } = useAppSelector(state => state.pcsPdp);
    const { enrolmentYears, enrolmentYearsStatus } = useAppSelector(state => state.pcsEnrolment);
    const { currentYear, currentYearStatus } = useAppSelector(state => state.pcs);

    useEffect(() => {
        dispatch(getCreditSummaryAsync());
    }, []);

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0) {
            setEnrolmentYear(enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId));
        }
    }, [enrolmentYears, enrolmentYearId]);

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0 && currentYear) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            setIsCurrentYearPlan(enrolmentYear?.year === currentYear);
        }
    }, [enrolmentYears, currentYear, reset]);

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0 && pcsPdpStatuses && currentYear) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            const pcsPdpStatus = pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId);

            setCanUpdatePlannedActivities((pcsPdpStatus?.code === PdpStatusEnum.Draft || pcsPdpStatus?.code === PdpStatusEnum.NotStarted)
                && enrolmentYear?.year === currentYear);
        }
    }, [enrolmentYears, pcsPdpStatuses, currentYear]);

    useEffect(() => {
        if (pcsPdpStatuses && enrolmentYears) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            setPcsPdpStatus(pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId));
        }
    }, [pcsPdpStatuses, enrolmentYears]);

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0 && pcsPdpStatuses && currentYear) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            const pcsPdpStatus = pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId);

            setCanViewReviewerDetails(pcsPdpStatus?.code === PdpStatusEnum.ReviewRequestPending
                || pcsPdpStatus?.code === PdpStatusEnum.UnderReview
                || pcsPdpStatus?.code === PdpStatusEnum.Reviewed);
        }
    }, [enrolmentYears, pcsPdpStatuses, currentYear]);

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0 && pcsPdpStatuses && currentYear) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            const pcsPdpStatus = pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId);

            setCanCancelReviewRequest((pcsPdpStatus?.code === PdpStatusEnum.UnderReview
                || pcsPdpStatus?.code === PdpStatusEnum.ReviewRequestPending) && enrolmentYear?.year === currentYear);
        }
    }, [enrolmentYears, pcsPdpStatuses, currentYear]);

    useEffect(() => {
        if (enrolmentYear) {
            reset({
                [External.name]: enrolmentYear.pdpExternal,
                [WorkBasedLearning.name]: enrolmentYear.pdpWorkBasedLearning,
                [PracticeReview.name]: enrolmentYear.pdpPracticeReview,
            })
        }
    }, [External.name, PracticeReview.name, WorkBasedLearning.name, enrolmentYear, reset]);

    const cancelPlan = () => {
        let cancelPlanObject: CancelPlanParameters = { enrolmentYearId: enrolmentYearId };
        dispatch(cancelPlanAsync(cancelPlanObject)).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                dispatch(getPcsEnrolmentYearsAsync()).then((response) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        toast.success(MESSAGE_PLAN_CANCELED);
                    }
                });
            }
        });;
    }

    useEffect(() => {
        if (enrolmentYear !== null && reviewerDetails === null && enrolmentYear &&
            enrolmentYear?.pdpReviewerPersonId !== null && canViewReviewerDetails === true) {
            dispatch(searchReviewerByPersonIdAsync(enrolmentYear?.pdpReviewerPersonId));
        }
    }, [enrolmentYear, reviewerDetails, pcsPdpStatus, dispatch]);

    if (staticDataStateStatus === ApiRequestStatus.Pending || planStateStatus === ApiRequestStatus.Pending
        || enrolmentYearsStatus === ApiRequestStatus.Pending || currentYearStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <ActivityDetails />

        <UpdatePlannedActivities canUpdatePlannedActivity={false} />

        {canViewReviewerDetails === true && <ViewReviewer />}

        {enrolmentYear && pcsPdpStatus?.code === PdpStatusEnum.Reviewed === true && <ViewFeedback />}

        {isCurrentYearPlan === true &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                <Grid container rowSpacing={4}>

                    <Grid item xs={12}></Grid>

                    {canUpdatePlannedActivities === true &&
                        <Grid item xs={9} sm={5} sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Button variant="contained" color="primary"
                                component={Link} to={`${AppPathEnum.PCS_REVIEWER_DETAILS}/${enrolmentYearId}`}>
                                Add reviewer
                            </Button>
                        </Grid>
                    }

                    {canCancelReviewRequest === true &&
                        <Grid item xs={4} sm={3} sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Button variant="contained" color="primary" onClick={() => cancelPlan()}>
                                Cancel review request
                            </Button>
                        </Grid>
                    }

                    {pcsPdpStatus?.code === PdpStatusEnum.Reviewed === true &&
                        <Grid item xs={4} sm={3} sx={{ display: 'flex', justifyContent: 'start' }}>
                            <Button variant="contained" color="primary"
                                component={Link} to={`${AppPathEnum.PCS_PLAN_FEEDBACK_ENROLMENT}/${enrolmentYearId}`}>
                                Edit my comments
                            </Button>
                        </Grid>
                    }

                </Grid>
            </Box>
        }
    </>
}