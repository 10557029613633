import * as yup from 'yup';
import pcsPlanFormModel from './pcsPlanFormModel';
import { EMAIL_EXPRESSION } from '../../../app/utils/constant';

const {
  formField: {
    External,
    WorkBasedLearning,
    PracticeReview,
    Title,
    FirstName,
    Surname,
    IMCNumber,
    EmailAddress,
    ReviewerComments
  }
} = pcsPlanFormModel;

export const plannedActivitiesValidationSchema = yup.object().shape({
  [External.name]: yup.string().required(`${External.requiredErrorMsg}`),
  [WorkBasedLearning.name]: yup.string().required(`${WorkBasedLearning.requiredErrorMsg}`),
  [PracticeReview.name]: yup.string().required(`${PracticeReview.requiredErrorMsg}`)
});

export const reviewerDetailsValidationSchema = yup.object().shape({
  [Title.name]: yup.string().required(`${Title.requiredErrorMsg}`),
  [FirstName.name]: yup.string().required(`${FirstName.requiredErrorMsg}`),
  [Surname.name]: yup.string().required(`${Surname.requiredErrorMsg}`),
  [IMCNumber.name]: yup.string().required(`${IMCNumber.requiredErrorMsg}`),
  [EmailAddress.name]: yup.string().matches(EMAIL_EXPRESSION, `${EmailAddress.typeError}`)
});

export const feedbackValidationSchema = yup.object().shape({
  [ReviewerComments.name]: yup.string().required(`${ReviewerComments.requiredErrorMsg}`)
});