import { FormHelperText, Grid } from "@mui/material";
import { useState } from "react";
import { UseControllerProps, useController } from "react-hook-form";
import AppInformationText from "./AppInformationText";
import { CheckBoxItem } from "../models/common/checkBoxItem";

interface Props extends UseControllerProps {
    options: CheckBoxItem[];
    label?: string;
    information_text?: string;
    disabled?: boolean;
}

const AppCheckboxField = (props: Props) => {
    const { fieldState, field } = useController({ ...props });
    const [, setValue] = useState(field.value || []);

    return (
        <Grid container>
            <Grid item xs={11}>
                {props?.options.map((item: CheckBoxItem) => (
                    <div key={item.id}>
                        <Grid container>
                            <Grid item xs={1} sm={0.5}>
                                <input
                                    {...field}
                                    onChange={(e) => {
                                        if (e.target.checked === true &&
                                            !field.value?.includes(item.id)) {
                                            if (!field.value) { field.value = [item.id]; }
                                            else { field.value.push(item.id); }
                                        }
                                        else {
                                            field.value = field.value.filter((e: string) => e !== item.id);
                                        }
                                        field.onChange(field.value);
                                        setValue(field.value);
                                    }}
                                    key={item.id}
                                    type="checkbox"
                                    checked={field.value === undefined ? false : field.value?.includes(item.id)}
                                    disabled={props.disabled}
                                    style={props.disabled === true ? { backgroundColor: '#f1f1f1', color: '#858282' } : {}}
                                    value={item.value} />
                            </Grid>

                            <Grid item xs={11} sm={11.5}>
                                <span className="mx-2">{item.value}</span>
                            </Grid>
                        </Grid>
                    </div>
                ))}
            </Grid>

            <Grid item xs={1}>
                <AppInformationText information_text={props.information_text} />
            </Grid>

            <Grid item xs={11} sm={12}>
                {fieldState.error &&
                    <FormHelperText sx={{ color: 'primary.main' }}>{fieldState.error.message}</FormHelperText>}
            </Grid>

        </Grid>
    );
};

export default AppCheckboxField;