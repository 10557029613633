import * as yup from 'yup';
import "yup-phone-lite";
import { alumniFilter, countryCodes, famFilter } from '../../../app/utils/util';
import { CountryCode } from 'libphonenumber-js/types';
import MembershipFormModel from './membershipFormModel';
import { EMAIL_EXPRESSION, MESSAGE_NOT_A_VALUE, MIN_AGE_TO_ENROLL } from '../../utils/constant';

const {
    formField: {
        FirstName,
        Surname,
        DateOfBirth,
        Gender,
        PreferredEmail1,
        AlternateEmail2,
        AlternateEmail3,
        CountryOfNationality,
        PreferredPhoneNumber,
        PostalAddressLines,
        PostalAddressCountry,
        LocationCountry,
        CountrySubdivision,
        SurgeonsScopeMagazine,
        TheSurgeonJournal,
        Declaration,
        Discipline,
        AreaOfPracticeMoreDetails,
        LinkedInUrl,
        CurrentUserRoles
    }
} = MembershipFormModel;

const areaOfPracticeMoreDetailsValidationSchema = yup.string().required(`${AreaOfPracticeMoreDetails.requiredErrorMsg}`)
    .when([Discipline.name, CurrentUserRoles.name],
        ([discipline, currentUserRoles], schema) => {
            const userRoles = currentUserRoles as unknown as string[];
            if (alumniFilter(userRoles) || famFilter(userRoles)) {
                return schema.test(`${AreaOfPracticeMoreDetails.name}`,
                    `${AreaOfPracticeMoreDetails.requiredErrorMsg}`,
                    function (areaOfPracticeMoreDetails) {
                        if (areaOfPracticeMoreDetails === MESSAGE_NOT_A_VALUE) {
                            return true;
                        }
                        if (discipline !== "" && areaOfPracticeMoreDetails === "") {
                            return false;
                        }
                        return true;
                    });
            }
            return yup.string().notRequired();
        });

const disciplineValidationSchema = yup.string().required(`${Discipline.requiredErrorMsg}`)
    .when([CurrentUserRoles.name],
        ([currentUserRoles], schema) => {
            const userRoles = currentUserRoles as unknown as string[];
            if (alumniFilter(userRoles) || famFilter(userRoles)) {
                return yup.string().required(`${Discipline.requiredErrorMsg}`);
            }
            return yup.string().notRequired();
        });

const alternateEmail2ValidationSchema = yup.string().test(`${AlternateEmail2.name}`,
    `${AlternateEmail2.typeError}`,
    function (alternateEmail2) {
        if (alternateEmail2 === "" || alternateEmail2 === undefined) {
            return true;
        }
        else {
            return EMAIL_EXPRESSION.test(alternateEmail2);
        }
    }
);

const alternateEmail3ValidationSchema = yup.string().test(`${AlternateEmail3.name}`,
    `${AlternateEmail3.typeError}`,
    function (alternateEmail3) {
        if (alternateEmail3 === "" || alternateEmail3 === undefined) {
            return true;
        }
        else {
            return EMAIL_EXPRESSION.test(alternateEmail3);
        }
    }
);

export const PersonalDetailsValidationSchema = yup.object().shape({
    [FirstName.name]: yup.string().required(`${FirstName.requiredErrorMsg}`),
    [Surname.name]: yup.string().required(`${Surname.requiredErrorMsg}`),
    [DateOfBirth.name]: yup.date()
        .min(new Date(new Date().setFullYear(new Date().getFullYear() - 100)), `${DateOfBirth.minAgeErrorMsg}`)
        .max(new Date(new Date().setFullYear(new Date().getFullYear() - MIN_AGE_TO_ENROLL)), `${DateOfBirth.maxAgeErrorMsg}`)
        .required(`${DateOfBirth.requiredErrorMsg}`),
    [Gender.name]: yup.string().required(`${Gender.requiredErrorMsg}`),
    [PreferredEmail1.name]: yup.string().required(`${PreferredEmail1.requiredErrorMesssag}`)
        .matches(EMAIL_EXPRESSION, `${PreferredEmail1.typeError}`),
    [AlternateEmail2.name]: alternateEmail2ValidationSchema,
    [AlternateEmail3.name]: alternateEmail3ValidationSchema,
    [CountryOfNationality.name]: yup.string().required(`${CountryOfNationality.requiredErrorMsg}`),
    [PreferredPhoneNumber.name]: yup.string().phone(countryCodes as CountryCode[], `${PreferredPhoneNumber.typeError}`)
        .required(`${PreferredPhoneNumber.requiredErrorMsg}`),
    [Discipline.name]: disciplineValidationSchema,
    [AreaOfPracticeMoreDetails.name]: areaOfPracticeMoreDetailsValidationSchema,
    [LinkedInUrl.name]: yup.string().notRequired().test({
        name: `${LinkedInUrl.name}`,
        message: () => `${LinkedInUrl.formatErrorMessage}`,
        test: (linkedInUrl) => (
            linkedInUrl === "" || linkedInUrl === undefined || linkedInUrl !== null &&
            new RegExp(LinkedInUrl.expression).test(linkedInUrl)
        )
    })
});

export const PostalAddressValidationSchema = yup.object().shape({
    [PostalAddressLines.name]: yup.string().required(`${PostalAddressLines.requiredErrorMsg}`).max(1000, PostalAddressLines.maxLengthErrorMessage),
    [PostalAddressCountry.name]: yup.string().required(`${PostalAddressCountry.requiredErrorMsg}`)
});

const BaseLocationValidationSchema = {
    [LocationCountry.name]: yup.string().required(`${LocationCountry.requiredErrorMsg}`),
    [CountrySubdivision.name]: yup.string().test(`${CountrySubdivision.name}`,
        `${CountrySubdivision.requiredErrorMsg}`,
        function (countrySubdivision) {
            if (countrySubdivision === MESSAGE_NOT_A_VALUE) {
                return true;
            }
            if (countrySubdivision === "") {
                return false;
            }
            return true;
        })
};

export const LocationValidationSchema = yup.object().shape({
    ...BaseLocationValidationSchema
});

export const LocationAndPreferencesValidationSchema = yup.object().shape({
    ...BaseLocationValidationSchema,
    [SurgeonsScopeMagazine.name]: yup.string().required(`${SurgeonsScopeMagazine.requiredErrorMesssag}`),
    [TheSurgeonJournal.name]: yup.string().required(`${TheSurgeonJournal.requiredErrorMesssag}`)
});

export const DeclarationValidationSchema = yup.object().shape({
    [Declaration.name]: yup.array().min(1, Declaration.requiredErrorMsg).of(yup.string().required(Declaration.requiredErrorMsg)).required()
});
