import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { CpdDto } from "../../features/pcsCpd/models/cpdDto";
import { CPDRecordStatusChange } from "../../features/pcsCpd/models/CPDRecordStatusChange";
import { CreditRecord } from "../../features/pcsCpd/models/creditRecord";
import { SearchCreditRecordsQuery } from "../../features/pcsCpd/models/searchCreditRecordsQuery";
import { MESSAGE_DETAILS_SAVED, MESSAGE_DLETED_SAVED } from "../utils/constant";
import { FileDto } from "../models/membership/fileDto";
import { GetDocumentParamObj } from "../../features/pcsCpd/common/getDocumentParamObj";

interface CpdResponseState {
    cpdDetails: CpdDto | null;
    creditRecords: CreditRecord[] | null,
    cpdStatusHistory: CPDRecordStatusChange[] | null,
    documents: FileDto[] | null,
    documentsByCreditRecordId: FileDto[] | null,

    addCpdStateStatus: ApiRequestStatus;
    cloneCpdStateStatus: ApiRequestStatus;
    updateCpdStateStatus: ApiRequestStatus;
    deleteCpdStateStatus: ApiRequestStatus;
    getCpdStatus: ApiRequestStatus;
    getCpdHistoryStatus: ApiRequestStatus;
    getCreditRecordsStatus: ApiRequestStatus;
    getDocumentsStatus: ApiRequestStatus;
    getDocumentsByCreditRecordIdStatus: ApiRequestStatus;
    addDocumentStatus: ApiRequestStatus;
    getDocumentStatus: ApiRequestStatus;
    deleteDocumentStatus: ApiRequestStatus;
}

const initialState: CpdResponseState = {
    cpdDetails: null,
    creditRecords: null,
    cpdStatusHistory: null,
    documents: null,
    documentsByCreditRecordId: null,

    addCpdStateStatus: ApiRequestStatus.Idle,
    cloneCpdStateStatus: ApiRequestStatus.Idle,
    updateCpdStateStatus: ApiRequestStatus.Idle,
    deleteCpdStateStatus: ApiRequestStatus.Idle,
    getCpdStatus: ApiRequestStatus.Idle,
    getCpdHistoryStatus: ApiRequestStatus.Idle,
    getCreditRecordsStatus: ApiRequestStatus.Idle,
    getDocumentsStatus: ApiRequestStatus.Idle,
    getDocumentsByCreditRecordIdStatus: ApiRequestStatus.Idle,
    addDocumentStatus: ApiRequestStatus.Idle,
    getDocumentStatus: ApiRequestStatus.Idle,
    deleteDocumentStatus: ApiRequestStatus.Idle
}

export const getDocumentsAsync = createAsyncThunk<FileDto[]>(
    'credits/getDocumentsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.Credits.getDocumentsAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCpdDetailsAsync = createAsyncThunk<CpdDto, string>(
    'credits/getCpdDetailsAsync',
    async (id, thunkAPI) => {
        try {
            return agent.Credits.getCpdDetailsAsync(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCreditRecordsAsync = createAsyncThunk<CreditRecord[], SearchCreditRecordsQuery>(
    'credits/getCreditRecordsAsync',
    async (searchCreditRecordsQuery, thunkAPI) => {
        try {
            return agent.Credits.getCreditRecordsAsync(searchCreditRecordsQuery);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updateCpdDetailsAsync = createAsyncThunk<void, CpdDto>(
    'credits/updateCpdDetailsAsync',
    async (cpdDetails, thunkAPI) => {
        try {
            return agent.Credits.updateCpdDetailsAsync(cpdDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const addCpdDetailsAsync = createAsyncThunk<void, CpdDto>(
    'credits/addCpdDetailsAsync',
    async (cpdDetails, thunkAPI) => {
        try {
            return agent.Credits.addCpdDetailsAsync(cpdDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const cloneCpdDetailsAsync = createAsyncThunk<void, CpdDto>(
    'credits/cloneCpdDetailsAsync',
    async (cpdDetails, thunkAPI) => {
        try {
            return agent.Credits.cloneCpdDetailsAsync(cpdDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const deleteCpdDetailsAsync = createAsyncThunk<void, string>(
    'credits/deleteCpdDetailsAsync',
    async (id, thunkAPI) => {
        try {
            return agent.Credits.deleteCpdDetailsAsync(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getDocumentsByCreditRecordIdAsync = createAsyncThunk<FileDto[], string>(
    'credits/getDocumentsByCreditRecordIdAsync',
    async (creditRecordId, thunkAPI) => {
        try {
            return agent.Credits.getDocumentsByCreditRecordIdAsync(creditRecordId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getDocumentAsync = createAsyncThunk<FormData, GetDocumentParamObj>(
    'credits/getDocumentAsync',
    async (getDocumentParamObj, thunkAPI) => {
        try {
            return agent.Credits.getDocumentAsync(getDocumentParamObj);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const addDocumentAsync = createAsyncThunk<void, FormData>(
    'credits/addDocumentAsync',
    async (formData, thunkAPI) => {
        try {
            return agent.Credits.addDocumentAsync(formData);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCpdStatusHistoryAsync = createAsyncThunk<CPDRecordStatusChange[], string>(
    'credits/getCpdStatusHistoryAsync',
    async (id, thunkAPI) => {
        try {
            return agent.Credits.getCpdStatusHistoryAsync(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const deleteDocumentAsync = createAsyncThunk<FormData, GetDocumentParamObj>(
    'credits/deleteDocumentAsync',
    async (getDocumentParamObj, thunkAPI) => {
        try {
            return agent.Credits.deleteDocumentAsync(getDocumentParamObj);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const creditsSlice = createSlice({
    name: 'creditsSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getDocumentsAsync.pending, (state) => {
            state.getDocumentsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getDocumentsAsync.fulfilled, (state, action) => {
            state.getDocumentsStatus = ApiRequestStatus.Fulfilled;
            state.documents = action.payload;
        });
        builder.addCase(getDocumentsAsync.rejected, (state) => {
            state.getDocumentsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getCpdDetailsAsync.pending, (state) => {
            state.getCpdStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCpdDetailsAsync.fulfilled, (state, action) => {
            state.getCpdStatus = ApiRequestStatus.Fulfilled;
            state.cpdDetails = action.payload;
        });
        builder.addCase(getCpdDetailsAsync.rejected, (state) => {
            state.getCpdStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getCreditRecordsAsync.pending, (state) => {
            state.getCreditRecordsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCreditRecordsAsync.fulfilled, (state, action) => {
            state.getCreditRecordsStatus = ApiRequestStatus.Fulfilled;
            state.creditRecords = action.payload.sort((a, b) => new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime());
        });
        builder.addCase(getCreditRecordsAsync.rejected, (state) => {
            state.getCreditRecordsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(addCpdDetailsAsync.pending, (state) => {
            state.addCpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(addCpdDetailsAsync.fulfilled, (state) => {
            state.addCpdStateStatus = ApiRequestStatus.Fulfilled;
            toast.success(MESSAGE_DETAILS_SAVED);
        });
        builder.addCase(addCpdDetailsAsync.rejected, (state) => {
            state.addCpdStateStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(cloneCpdDetailsAsync.pending, (state) => {
            state.cloneCpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(cloneCpdDetailsAsync.fulfilled, (state) => {
            state.cloneCpdStateStatus = ApiRequestStatus.Fulfilled;
            toast.success(MESSAGE_DETAILS_SAVED);
        });
        builder.addCase(cloneCpdDetailsAsync.rejected, (state) => {
            state.cloneCpdStateStatus = ApiRequestStatus.Rejected;
            toast.error('Error while adding CPD.');
        });

        builder.addCase(updateCpdDetailsAsync.pending, (state) => {
            state.updateCpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(updateCpdDetailsAsync.fulfilled, (state) => {
            state.updateCpdStateStatus = ApiRequestStatus.Fulfilled;
            toast.success(MESSAGE_DETAILS_SAVED);
        });
        builder.addCase(updateCpdDetailsAsync.rejected, (state) => {
            state.updateCpdStateStatus = ApiRequestStatus.Rejected;
            toast.error('Error while updating CPD.');
        });

        builder.addCase(deleteCpdDetailsAsync.pending, (state) => {
            state.deleteCpdStateStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(deleteCpdDetailsAsync.fulfilled, (state) => {
            state.deleteCpdStateStatus = ApiRequestStatus.Fulfilled;
            toast.success(MESSAGE_DLETED_SAVED);
        });
        builder.addCase(deleteCpdDetailsAsync.rejected, (state) => {
            state.deleteCpdStateStatus = ApiRequestStatus.Rejected;
            toast.error('Error while deleting CPD.');
        });

        builder.addCase(getCpdStatusHistoryAsync.pending, (state) => {
            state.getCpdHistoryStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getCpdStatusHistoryAsync.fulfilled, (state, action) => {
            state.getCpdHistoryStatus = ApiRequestStatus.Fulfilled;
            state.cpdStatusHistory = action.payload;
        });
        builder.addCase(getCpdStatusHistoryAsync.rejected, (state) => {
            state.getCpdHistoryStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getDocumentsByCreditRecordIdAsync.pending, (state) => {
            state.getDocumentsByCreditRecordIdStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getDocumentsByCreditRecordIdAsync.fulfilled, (state, action) => {
            state.getDocumentsByCreditRecordIdStatus = ApiRequestStatus.Fulfilled;
            state.documentsByCreditRecordId = action.payload;
        });
        builder.addCase(getDocumentsByCreditRecordIdAsync.rejected, (state) => {
            state.getDocumentsByCreditRecordIdStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getDocumentAsync.pending, (state) => {
            state.getDocumentStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getDocumentAsync.fulfilled, (state, action) => {
            state.getDocumentStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getDocumentAsync.rejected, (state) => {
            state.getDocumentStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(addDocumentAsync.pending, (state) => {
            state.addDocumentStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(addDocumentAsync.fulfilled, (state) => {
            state.addDocumentStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(addDocumentAsync.rejected, (state) => {
            state.addDocumentStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(deleteDocumentAsync.pending, (state) => {
            state.deleteDocumentStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(deleteDocumentAsync.fulfilled, (state) => {
            state.deleteDocumentStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(deleteDocumentAsync.rejected, (state) => {
            state.deleteDocumentStatus = ApiRequestStatus.Rejected;
        });
    })
})
