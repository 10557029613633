const MembershipFormModel = {
  formField: {
    Title: {
      name: 'Title',
      label: 'Title'
    },
    FirstName: {
      name: 'FirstName',
      label: 'First name*',
      requiredErrorMsg: 'First name is required.',
      information_text: 'Please note, the name included on this application will be the name printed on your award, including first name, middle name and surname. We advise the name on your certificate matches the name on your passport.'
    },
    MiddleName: {
      name: 'MiddleName',
      label: 'Middle name'
    },
    Surname: {
      name: 'Surname',
      label: 'Surname*',
      requiredErrorMsg: 'Surname is required.'
    },
    Preferredname: {
      name: 'PreferredName',
      label: 'Preferred name'
    },
    DateOfBirth: {
      name: 'DateOfBirth',
      label: 'Date of birth*',
      minAgeErrorMsg: 'Person can\'t be older than 100 years.',
      maxAgeErrorMsg: 'Person should be at least 16 year old.',
      requiredErrorMsg: 'Date of birth is required.'
    },
    Gender: {
      name: 'Gender',
      label: 'Gender*',
      requiredErrorMsg: 'Gender is required.'
    },
    CountryOfNationality: {
      name: 'CountryOfNationality',
      label: 'Country of nationality*',
      requiredErrorMsg: 'Country of nationality is required.'
    },
    PreferredEmail1: {
      name: 'PreferredEmail1',
      label: 'Preferred email 1*',
      typeError: 'Preferred email 1 is not valid',
      requiredErrorMesssag: 'Preferred email 1 is required',
      informationText: <>This email address will be used for all portal related communications. This is a mandatory field. <br/><br/>Please note: if you change your preferred email address, you may start to receive communications you had previously opted out of. If this happens, simply unsubscribe as required.</>
    },
    AlternateEmail2: {
      name: 'AlternateEmail2',
      label: 'Alternate email 2',
      typeError: 'Alternate email 2 is not valid'
    },
    AlternateEmail3: {
      name: 'AlternateEmail3',
      label: 'Alternate email 3',
      typeError: 'Alternate email 3 is not valid'
    },
    PreferredPhoneNumber: {
      name: 'PreferredPhoneNumber',
      label: 'Preferred phone number*',
      requiredErrorMsg: 'Preferred phone number is required.',
      typeError: 'Invalid preferred phone number.'
    },
    AlternatePhoneNumber: {
      name: 'AlternatePhoneNumber',
      label: 'Alternate phone number',
      typeError: 'Invalid preferred phone number.'
    },
    LinkedInUrl: {
      name: 'LinkedInUrl',
      label: 'LinkedIn URL',
      formatErrorMessage: 'Invalid URL format',
      requiredErrorMesssag: 'LinkedIn URL is required',
      expression: /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
    },
    Discipline: {
      name: 'Discipline',
      label: 'Discipline*',
      requiredErrorMsg: 'Discipline is required.'
    },
    AreaOfPracticeMoreDetails: {
      name: 'AreaOfPracticeMoreDetails',
      label: 'Area of practice*',
      requiredErrorMsg: 'Area of practice is required.'
    },
    PostalAddressLines: {
      name: 'PostalAddressLines',
      label: 'Address*',
      requiredErrorMsg: 'Address is required.',
      maxLengthErrorMessage: 'Max 1000 characters allowed',
      information_text: 'Please exclude country from \'address\' field and select it using the \'address country\' dropdown below.'
    },
    PostalAddressCountry: {
      name: 'PostalAddressCountry',
      label: 'Country*',
      requiredErrorMsg: 'Country is required.'
    },
    LocationCountry: {
      name: 'LocationCountry',
      label: 'Country of Practice*',
      requiredErrorMsg: 'Country of Practice is required.',
      information_text: 'Location determines membership rate.'
    },
    CountrySubdivision: {
      name: 'CountrySubdivision',
      label: 'County/state/province*',
      requiredErrorMsg: 'County/state/province is required.'
    },
    CountryOfPractice: {
      name: 'CountryOfPractice',
      label: 'Country of practice'
    },
    IMCNumber: {
      name: 'IMCNumber',
      label: 'IMC number'
    },
    RegType: {
      name: 'RegType',
      label: 'Reg type'
    },
    Scheme: {
      name: 'Scheme',
      label: 'Scheme'
    },
    EngagedInMedicine: {
      name: 'EngagedInMedicine',
      label: 'Engaged in medicine'
    },
    RegistrationSpecialty: {
      name: 'RegistrationSpecialty',
      label: 'Registration specialty '
    },
    Subspecialty: {
      name: 'Subspecialty',
      label: 'Subspecialty'
    },
    EducationDetails: {
      name: 'EducationDetails',
      label: 'Education details'
    },
    WorkDetails: {
      name: 'WorkDetails',
      label: 'Work details'
    },
    WorkDetails1: {
      name: 'WorkDetails',
      label: 'Work details'
    },
    AlumniMagazinePreference: {
      name: 'AlumniMagazinePreference',
      label: 'Alumni magazine preference*',
      requiredErrorMesssag: 'Alumni magazine preference is required'
    },
    SurgeonsScopeMagazine: {
      name: 'SurgeonsScopeMagazine',
      label: 'Surgeons Scope magazine*',
      requiredErrorMesssag: 'Surgeons Scope magazine is required.'
    },
    TheSurgeonJournal: {
      name: 'TheSurgeonJournal',
      label: 'The Surgeon journal*',
      requiredErrorMesssag: 'The Surgeon journal is required.'
    },
    OptIntoDirectMarketing: {
      name: 'OptIntoDirectMarketing',
      label: `Select 'Yes' to stay up-to-date with news, events and to receive 
        personalised communications and promotions from RCSI.`,
      information_text: `Curious how we use your data? Email alumni@rcsi.com to 
        receive our data protection statement.`
    },
    CurrentUserRoles: {
      name: 'CurrentUserRoles'
    },
    ShareMyEmailWithMyClassmates: {
      name: 'ShareMyEmailWithMyClassmates',
      label: 'Share my email with my classmates'
    },
    Declaration: {
      name: 'Declaration',
      label: `Declaration:

      I do solemnly and sincerely declare and promise that I will observe and be obedient to the Statutes,
      Bye-Laws and Ordinances of the Royal College of Surgeons
      in Ireland, and that I will to the utmost of my power,
      endeavour to promote the reputation, honour and dignity of
      the said College. I promise to place the welfare of my patients above all else. I promise to be respectful of my fellow healthcare
      professionals and will readily offer them my assistance and
      support. I further promise to continue to learn and teach and
      maintain my competence, for the benefit of my patients,
      trainees and the society in which I serve.” 
      
      By ticking this box, you confirm that you agree to abide by the College's Statutes, Bye-Laws, and Ordinances of Fellowship.*`,
      requiredErrorMsg: 'Declaration is required.'
    },
    MembershipDeclaration: {
      name: 'MembershipDeclaration',
      label: <>
        If you have been successfully accepted as an exam candidate for the MRCS Part A or Part B exams do not 
        complete this application as you will automatically receive Affiliate Membership through that programme. 
        
        <br></br>
        <br></br>
        
        If you have already sat the MRCS Part B exam and you have passed or you are awaiting results,
        you are not currently eligible for RCSI Affiliate Membership.
        Please email <a href="mailto:fellows@rcsi.com">fellows@rcsi.com</a> for more information.


        <br></br>
        <br></br>
        <b>
          By ticking this box, you confirm that you have <u>not</u> sat the MRCS Part B exam with RCSI
          or one of the Royal Colleges (RCSEd, RCSEng, or RCPSG) and passed, or are awaiting results.
        </b>
      </>,
      requiredErrorMsg: 'Declaration is required.'
    },
    MembershipType: {
      name: 'MembershipType',
      label: 'Membership type'
    },
    MembershipCountry: {
      name: 'MembershipCountry',
      label: 'Country of Practice'
    },
    MembershipFeeDue: {
      name: 'MembershipFeeDue',
      label: 'Fee due'
    },
    EducationalDetailsCountry: {
      name: 'EducationalDetailsCountry',
      label: 'Country of medical school*',
      requiredErrorMsg: 'Country of medical school is required.'
    },
    UniversityOrCollegePrimaryMedicalDegree: {
      name: 'UniversityOrCollegePrimaryMedicalDegree',
      label: 'Medical school*',
      requiredErrorMsg: 'Medical school is required.'
    },
    QualificationYear: {
      name: 'QualificationYear',
      label: 'Qualification year'
    },
    Award: {
      name: 'Award',
      label: 'Award*',
      requiredErrorMsg: 'Award is required.'
    },
    IsCourseCompleted: {
      name: 'IsCourseCompleted',
      label: 'Have you completed the course?*',
      requiredErrorMsg: 'Your choice is required here'
    },
    YearOfConferring: {
      name: 'YearOfConferring',
      label: 'Year of conferring*',
      typeError: 'Invalid year of conferring',
      requiredErrorMsg: 'Year of conferring is required.',
      minErrorMsg: 'Year of conferring should be in between ##MIN_YEAR## and ##MAX_YEAR##.',
      maxErrorMsg: 'Year of conferring shouldn\'t be future year.'
    },
    LevelOfStudyOrTraining: {
      name: 'LevelOfStudyOrTraining',
      label: 'Level of study/training*',
      requiredErrorMsg: 'Level of study/training is required.'
    },
    StatusAffiliateMemberPathway: {
      name: 'StatusAffiliateMemberPathway',
      label: 'Affiliate member pathway*',
      requiredErrorMsg: 'Affiliate member pathway is required.',
      information_text: <>
        If one of the following applies to you, you are eligible to apply for one subscription year free:
        <ul>
          <li>Enrolled on Master of Surgery</li>
          <li>Active Member of RCSI Surgical Society - Dublin and Bahrain</li>
          <li>Doctors from regions impacted by war</li>
        </ul>
        All other applicants should select 'General Applicant'
      </>
    },
    AffiliateMemberPathwayComments: {
      name: 'AffiliateMemberPathwayComments'
    },
    HaveNotSuccessfullyPassedMrcsPartBExam: {
      name: 'HaveNotSuccessfullyPassedMrcsPartBExam',
      requiredErrorMsg: 'Your choice is required here'
    },
    ReasonToBecomeAffiliateMemberOfRCSI: {
      name: 'ReasonToBecomeAffiliateMemberOfRCSI',
      label: 'Briefly outline why you wish to become an Affiliate Member of RCSI.*',
      requiredErrorMsg: 'Reason is required.'
    },
    NameOnCertificate: {
      name: 'NameOnCertificate',
      label: 'Name on certificate*',
      requiredErrorMsg: 'Name on certificate is required.',
      information_text: `Please confirm your full name as it should appear on your certificate. We advise that the name on your 
        certificate matches the name on your passport for the purpose of verification. You may be required to submit a copy 
        of your passport to verify the name you wish to appear on your certificate if it differs to the name on your exam results. 
        Any certificate reprints required due to an error or typo in the name provided may incur a charge.`
    },
    PetitionForElection: {
      name: 'PetitionForElection',
      label: 'Petition for Election: To be deemed eligible for election to the College at an RCSI Council meeting, please tick the Petition for Election box*',
      requiredErrorMsg: 'Petition for Election is required.',
      information_text: 'Pending Approval: I request that my name be placed before the Council of the Royal College of Surgeons in Ireland (RCSI) for election ' +
        'as a Fellow or Member of the College, having passed the necessary examinations.'
    },
    Certificate: {
      name: 'Certificate',
      label: 'Copy of membership certificate issued by other Royal College*',
      requiredErrorMsg: 'A copy of your certificate is required to progress.',
      invalidFileTypeErrorMsg: 'Please provide .png or .pdf file type.',
      invalidFileSizeErrorMsg: 'File too large, cannot exceed, 5MB.',
      fileNotUploaded: `Certificate not uploaded.`
    },
    Exam: {
      name: 'Exam',
      label: 'Written verification of having previously passed the Intercollegiate Membership exam (MRCS Part B OSCE)*',
      requiredErrorMsg: 'Written verification of your MRCS Part B OSCE result is required to progress.',
      invalidFileTypeErrorMsg: 'Please provide .png or .pdf file type.',
      invalidFileSizeErrorMsg: 'File too large, cannot exceed, 5MB.',
      fileNotUploaded: `Exam document not uploaded.`
    },
    CV: {
      name: 'CV',
      label: 'A current curriculum vitae*',
      requiredErrorMsg: 'A copy of your current curriculum vitae is required to progress.',
      invalidFileTypeErrorMsg: 'Please provide .png or .pdf file type.',
      invalidFileSizeErrorMsg: 'File too large, cannot exceed, 5MB.',
      fileNotUploaded: `CV not uploaded.`
    },
    WhichRoyalCollegeAreYouTransferringFrom: {
      name: 'WhichRoyalCollegeAreYouTransferringFrom',
      label: 'Which Royal College are you transferring from?*',
      requiredErrorMsg: 'Please select which Royal College are you transferring from?',
      information_text: 'Membership of RCSI can be achieved by transfer from one of the following Royal Colleges: RCSEd, RCSEng and RCPSG.'
    }
  }
};

export default MembershipFormModel;
