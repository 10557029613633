import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    titleContainer: {
        flexDirection: 'row',
        marginTop: 5,
        fontSize: 9,
        fontFamily: 'Helvetica'
    },
    fieldLeft: {
        width: '30%'
    },
    fieldRight: {
        width: '40%'
    }
});

const ReceiptItems = () => (
    <>
        <View style={styles.titleContainer}>
            <Text style={styles.fieldLeft}>
                Term
            </Text>

            <Text style={styles.fieldRight}>

            </Text>
        </View>

        <View style={styles.titleContainer}>
            <Text style={styles.fieldLeft}>
                Name
            </Text>

            <Text style={styles.fieldRight}>

            </Text>
        </View>

        <View style={styles.titleContainer}>
            <Text style={styles.fieldLeft}>
                Scheme
            </Text>

            <Text style={styles.fieldRight}>

            </Text>
        </View>

        <View style={styles.titleContainer}>
            <Text style={styles.fieldLeft}>
                Date of Enrolment
            </Text>

            <Text style={styles.fieldRight}>

            </Text>
        </View>

        <View style={styles.titleContainer}>
            <Text style={styles.fieldLeft}>
                Date of Last Verification
            </Text>

            <Text style={styles.fieldRight}>

            </Text>
        </View>

        <View style={styles.titleContainer}>
            <Text style={styles.fieldLeft}>
                Medical Council Number
            </Text>

            <Text style={styles.fieldRight}>

            </Text>
        </View>

        <View style={styles.titleContainer}>
            <Text style={styles.fieldLeft}>
                Registration Type
            </Text>

            <Text style={styles.fieldRight}>

            </Text>
        </View>
    </>
);

export default ReceiptItems;