import { EMAIL_EXPRESSION } from "../../../app/utils/constant";

const pdpFormModel = {
    formId: 'CpdForm',
    formField: {
      StartDate: {
        name: 'StartDate',
        label: 'Start Date'
      },
      PostLevel: {
        name: 'PostLevel',
        label: 'Post Level'
      },
      NameOfEmployer: {
        name: 'NameOfEmployer',
        label: 'Name of Employer'
      },
      External: {
        name: 'External',
        label: 'External',
        requiredErrorMsg: 'External is required'
      },
      WorkBasedLearning: {
        name: 'WorkBasedLearning',
        label: 'Work Based Learning',
        requiredErrorMsg: 'Work Based Learning is required'
      },
      PracticeReview: {
        name: 'PracticeReview',
        label: 'Practice Review',
        requiredErrorMsg: 'Practice Review is required'
      },
      Name: {
        name: 'Name',
        label: 'Name'
      },
      Title: {
        name: 'Title',
        label: 'Title'
      },
      IMCNumber: {
        name: 'IMCNumber',
        label: 'IMC Number'
      },
      EmailAddress: {
        name: 'EmailAddress',
        label: 'Email Address',
        typeError: 'Email Address is not valid'
      },
      ReviewerConfirmation: {
        name: 'ReviewerConfirmation',
        label: 'I confirm I have Reviewed*',
        typeError: 'Reviewer confirmation is required',
        requiredErrorMsg: 'Reviewer confirmation is required'
      },
      ReviewerComments: {
        name: 'ReviewerComments',
        label: 'Reviewer Comments'
      },
      PractionerComments: {
        name: 'PractionerComments',
        label: 'Practioner Comments'
      }
    }
  };
  
  export default pdpFormModel;